import React, { useContext } from 'react'
import { OrdersStepProps } from './types'
import { CURRENY_COUNTRY_LABELS, GOODS_CURRENCY_DATA, SERVICE_TYPE } from 'containers/FPLOrderCreate/constants'
import { FileUpload } from './FileUpload'
import { buildBoxesForB2BBundleByUploadFile, buildBoxesForB2BOrder, buildBoxesForB2COrder } from './utils'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { OCMethodSelection } from './OCMethodSelection'
import { OCMethod } from './constants'
import { ManualKeyboard } from './ManualKeyboard'

const OrdersStep = ({ selectedService, saveOCMethod }: OrdersStepProps) => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { destination_country: destinationCountry } = ocState.selectedService
  const { validOrders, invalidOrders, allOrders, fileName, validRows, invalidRows, ocMethod } = ocState

  const changeMethod = method => {
    saveOCMethod(method)
  }

  const handleClickOptionButton = type => {
    changeMethod(type)
  }

  const handleSaveBulkUpload = () => {
    const currency = CURRENY_COUNTRY_LABELS[destinationCountry] || GOODS_CURRENCY_DATA.USD
    let boxData = []
    switch (selectedService.type) {
      case SERVICE_TYPE.MMCCB2C:
        boxData = buildBoxesForB2COrder(validOrders, currency, destinationCountry)
        break
      case SERVICE_TYPE.MMCCB2B:
        boxData = buildBoxesForB2BOrder(validOrders, currency, destinationCountry)
        break
      case SERVICE_TYPE.B2B_BUNDLE:
        boxData = buildBoxesForB2BBundleByUploadFile(validOrders, currency, destinationCountry)
    }
    updateOCState({ boxOrders: boxData })
  }

  const handleProcessDataUpload = (
    {
      allOrders: aOrders,
      validRows: vRows,
      invalidRows: inRows,
      validItemisedOrders: vItemisedOrders,
      invalidItemisedOrders: inItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfInvalidItemsInInvalidOrders,
      numberOfValidItemsInInvalidOrders,
      errorsByBoxLevel,
      errorsByParcelLevel
    },
    uploadedFileName
  ) => {
    updateOCState({
      allOrders: aOrders,
      validOrders: vItemisedOrders,
      invalidOrders: inItemisedOrders,
      validRows: vRows,
      invalidRows: inRows,
      fileName: uploadedFileName,
      numberOfValidItemsInValidOrders,
      numberOfInvalidItemsInInvalidOrders,
      numberOfValidItemsInInvalidOrders,
      errorsByBoxLevel,
      errorsByParcelLevel
    })
  }

  return (
    <>
      {!ocMethod && <OCMethodSelection onMethodChange={method => changeMethod(method)} />}
      {ocMethod === OCMethod.UPLOAD && (
        <>
          <FileUpload
            destinationCountry={selectedService.destination_country}
            allOrders={allOrders}
            validOrders={validOrders}
            invalidOrders={invalidOrders}
            validRows={validRows}
            invalidRows={invalidRows}
            fileName={fileName}
            serviceType={selectedService.type}
            onMethodChange={handleClickOptionButton}
            onSaveOrders={handleSaveBulkUpload}
            onProcessOrders={handleProcessDataUpload}
          />
        </>
      )}
      {ocMethod === OCMethod.KEYBOARD && (
        <>
          <ManualKeyboard />
        </>
      )}
    </>
  )
}

export { OrdersStep }
