/* eslint-disable max-len */
import { PICKUP_TYPES } from 'containers/PickupType/constants'

export const INTERNAL_STEP_STATUS = {
  current: 'current',
  completed: 'completed',
  unvisited: 'unvisited'
}

export enum MMCC_OC_STEP {
  SERVICE_DETAILS,
  ORDERS,
  COMMERCIAL_INVOICE,
  DELIVERY_ORDER,
  ADDITIONAL_DOCUMENT,
  REVIEW,
  DONE
}

export enum FILE_UPLOAD_STATUS {
  READY_TO_UPLOAD,
  UPLOADED,
  IN_PREVIEW
}

export const enum SUB_STEP_STATUS {
  IN_ADDING,
  IN_EDTING,
  IN_LIST,
  CLOSE
}

export enum KEYBOARD_INPUT_STATUS {
  IN_SHOWING,
  IN_PREVIEW
}

export enum SUPPORTED_DOCUMENT_STATUS {
  READY_TO_UPLOAD,
  UPLOADED_SUCCESS,
  UPLOADED_FAIL,
  IN_PREVIEW,
  SKIP
}

export enum UPLOAD_ERROR_TYPE {
  BAG,
  PARCEL
}

export const LIMIT = 100

export const SERVICE_SUB_STEPS = {
  NONE: 'NONE',
  SENDER_ADDRESS: 'SENDER_ADDRESS',
  SERVICE: 'SERVICE',
  CUSTOMS_CLEARANCE_TYPE: 'CUSTOMS_CLEARANCE_TYPE',
  DELIVERY_TYPE: 'DELIVERY_TYPE',
  RECIPIENT_ADDRESS: 'RECIPIENT_ADDRESS',
  PICKUP_TYPE: 'PICKUP_TYPE',
  PICKUP_ADDRESS: 'PICKUP_ADDRESS',
  TIME: 'TIME',
  VOLUMES: 'VOLUMES',
  CUSTOMS_CURRENCY: 'CUSTOMS_CURRENCY'
}

export const OUTSIDE_ASIA_COUNTRY_CODES = {
  CN: 'CN'
}

export const PICKUP_METADATA = {
  [PICKUP_TYPES.SCHEDULED]: {
    title: 'scheduled_pickup',
    description: 'scheduled_pickup_boxes_desc'
  },
  [PICKUP_TYPES.NO]: {
    title: 'no_pickup',
    description: 'no_pickup_boxes_desc'
  }
}

export enum OCMethod {
  KEYBOARD = 7,
  UPLOAD = 8
}

export const MMCC_KEYS = {
  BOX_ID: 'international.template.header.shipper_box_id', // 'Shipper Box ID'
  BOX_WEIGHT: 'international.template.header.box_weight', // "Box's Weight (Optional)"
  BOX_LENGTH: 'international.template.header.box_length', //"Box's Length (Optional)"
  BOX_HEIGHT: 'international.template.header.box_height', //"Box's Height (Optional)"
  BOX_WIDTH: 'international.template.header.box_width', // "Box's Width (Optional)"
  GOODS_DESCRIPTION: 'international.template.header.goods_description', // 'Goods Description'
  NO_OF_PARCELS: 'international.template.header.no_of_parcels', // 'No. of Parcels'
  PARCEL_TRACKING_ID: 'international.template.header.parcel_tracking_id', //'Parcel tracking ID'
  TO_NAME: 'international.template.header.to_name', //'To Name'
  TO_ADDRESS: 'international.template.header.to_address', // 'To Address'
  TO_CONTACT_NUMBER: 'international.template.header.to_contact_number', // 'To Contact Number'
  ITEM_QUANTITY: 'international.template.header.item_quantity', // 'Item Quantity',
  ITEM_DESCRIPTION: 'international.template.header.item_description', // 'Item Description'
  ITEM_VALUE: 'international.template.header.item_value', // 'Item Value'
  QUANTITY: 'international.template.header.parcel_quantity', // 'Quantity'
  GOODS_VALUE: 'international.template.header.goods_value', // 'Goods Value'
  RECIPIENT_NAME: 'international.template.header.recipient_name', // "Recipient's Name"
  RECIPIENT_PHONE_NUMBER: 'international.template.header.recipient_phone_number', // "Recipient's Phone Number"
  RECIPIENT_ADDRESS: 'international.template.header.recipient_address', // "Recipient's Address"
  RECIPIENT_CITY: 'international.template.header.recipient_city', // "Recipient's City"
  RECIPIENT_POSTCODE: 'international.template.header.recipient_postcode', //"Recipient's Postcode"
  REQUESTED_TRACKING_ID: 'international.template.header.requested_tracking_id', // Requested Tracking Id
  RECIPIENT_STATE_PROVINCE: 'international.template.header.recipient_state_province', // "Recipient's State/Province"
  CARTON_WEIGHT: 'international.template.header.carton_weight', // Carton's Weight (Optional)
  CARTON_LENGTH: 'international.template.header.carton_length', // Carton's Length (Optional)
  CARTON_HEIGHT: 'international.template.header.carton_height', // Carton's Height (Optional)
  CARTON_WIDTH: 'international.template.header.carton_width', // Carton's Width (Optional)
  CARTON_DESCRIPTION: 'international.template.header.carton_description', // Carton Description
  NUMBER_OF_CARTONS: 'international.template.header.no_of_carton', // Number of Cartons
  TOTAL_CARTON_VALUE: 'international.template.header.total_carton_value', // Total Carton Value
  HSCODE: 'international.template.header.hs_code', // HS Code (Optional)
  GRN: 'international.template.header.grn', // GRN required? (Optional)
  RDO: 'international.template.header.rdo', // RDO required? (Optional)
  SHIPPER_ORDER_NUMBER: 'international.template.header.shipper_order_number', // Shipper Order Number (Optional)
  REQUESTED_PIECE_TRACKING_NUMBERS: 'international.template.header.requested_piece_tracking_numbers' // Requested Piece Tracking Numbers (Optional)
}

// purpose on download sample template and display header in review error modal
export const MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME = {
  en: {
    [MMCC_KEYS.BOX_ID]: 'Shipper Box ID',
    [MMCC_KEYS.BOX_WEIGHT]: "Box's Weight (Optional)",
    [MMCC_KEYS.BOX_LENGTH]: "Box's Length (Optional)",
    [MMCC_KEYS.BOX_HEIGHT]: "Box's Height (Optional)",
    [MMCC_KEYS.BOX_WIDTH]: "Box's Width (Optional)",
    [MMCC_KEYS.GOODS_DESCRIPTION]: 'Goods Description',
    [MMCC_KEYS.NO_OF_PARCELS]: 'No. of Parcels',
    [MMCC_KEYS.PARCEL_TRACKING_ID]: 'Parcel Tracking ID',
    [MMCC_KEYS.TO_NAME]: 'To Name',
    [MMCC_KEYS.TO_ADDRESS]: 'To Address',
    [MMCC_KEYS.TO_CONTACT_NUMBER]: 'To Contact Number',
    [MMCC_KEYS.ITEM_QUANTITY]: 'Item Quantity',
    [MMCC_KEYS.ITEM_DESCRIPTION]: 'Item Description',
    [MMCC_KEYS.ITEM_VALUE]: 'Item Value',
    [MMCC_KEYS.QUANTITY]: 'Quantity',
    [MMCC_KEYS.GOODS_VALUE]: 'Goods Value',
    [MMCC_KEYS.RECIPIENT_NAME]: "Recipient's Name",
    [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: "Recipient's Phone Number",
    [MMCC_KEYS.RECIPIENT_ADDRESS]: "Recipient's Address",
    [MMCC_KEYS.RECIPIENT_CITY]: "Recipient's City",
    [MMCC_KEYS.RECIPIENT_POSTCODE]: "Recipient's Postcode",
    [MMCC_KEYS.REQUESTED_TRACKING_ID]: 'Requested Tracking Id (Optional)',
    [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: "Recipient's State/Province",
    [MMCC_KEYS.CARTON_WEIGHT]: "Carton's Weight (Optional)",
    [MMCC_KEYS.CARTON_LENGTH]: "Carton's Length (Optional)",
    [MMCC_KEYS.CARTON_HEIGHT]: "Carton's Height (Optional)",
    [MMCC_KEYS.CARTON_WIDTH]: "Carton's Width (Optional)",
    [MMCC_KEYS.CARTON_DESCRIPTION]: 'Carton Description',
    [MMCC_KEYS.NUMBER_OF_CARTONS]: 'Number of Cartons',
    [MMCC_KEYS.TOTAL_CARTON_VALUE]: 'Total Carton Value',
    [MMCC_KEYS.HSCODE]: 'HS Code (Optional)',
    [MMCC_KEYS.GRN]: 'GRN required? (Optional)',
    [MMCC_KEYS.RDO]: 'RDO required? (Optional)',
    [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: 'Shipper Order Number (Optional)',
    [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: 'Requested Piece Tracking Numbers (Optional)'
  },
  'zh-CN': {
    [MMCC_KEYS.BOX_ID]: 'Shipper Box ID 自定义箱号',
    [MMCC_KEYS.BOX_WEIGHT]: "Box's Weight (Optional) 箱重（选填）",
    [MMCC_KEYS.BOX_LENGTH]: "Box's Length (Optional) 箱长（选填）",
    [MMCC_KEYS.BOX_HEIGHT]: "Box's Height (Optional) 箱高（选填）",
    [MMCC_KEYS.BOX_WIDTH]: "Box's Width (Optional) 箱宽（选填）",
    [MMCC_KEYS.GOODS_DESCRIPTION]: 'Goods Description 产品描述（英文品名）',
    [MMCC_KEYS.NO_OF_PARCELS]: 'No. of Parcels 箱内包裹数',
    [MMCC_KEYS.PARCEL_TRACKING_ID]: 'Parcel Tracking ID 包裹单号',
    [MMCC_KEYS.TO_NAME]: 'To Name 包裹收件人姓名（小包裹维度）',
    [MMCC_KEYS.TO_ADDRESS]: 'To Address 包裹收件地址（小包裹维度）',
    [MMCC_KEYS.TO_CONTACT_NUMBER]: 'To Contact Number 包裹收件人电话（小包裹维度）',
    [MMCC_KEYS.ITEM_QUANTITY]: 'Item Quantity 包裹内产品数量',
    [MMCC_KEYS.ITEM_DESCRIPTION]: 'Item Description 产品描述',
    [MMCC_KEYS.ITEM_VALUE]: 'Item Value 包裹内每产品申报单价',
    [MMCC_KEYS.QUANTITY]: 'Quantity of Goods in Box 箱内产品数量',
    [MMCC_KEYS.GOODS_VALUE]: 'Goods Value 申报单价',
    [MMCC_KEYS.RECIPIENT_NAME]: "Recipient's Name 收件人姓名（箱子维度）",
    [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: "Recipient's Phone Number 收件人电话（箱子维度）",
    [MMCC_KEYS.RECIPIENT_ADDRESS]: "Recipient's Address 收件地址（箱子维度）",
    [MMCC_KEYS.RECIPIENT_CITY]: "Recipient's City 收件人所在城市",
    [MMCC_KEYS.RECIPIENT_POSTCODE]: "Recipient's Postcode 收件人的邮政编码",
    [MMCC_KEYS.REQUESTED_TRACKING_ID]: 'Requested Tracking Id (Optional) 请求的追踪编号',
    [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: "Recipient's State/Province 收件人所在州/省",
    [MMCC_KEYS.CARTON_WEIGHT]: "Carton's Weight (Optional) 纸箱重量",
    [MMCC_KEYS.CARTON_LENGTH]: "Carton's Length (Optional) 纸箱长度",
    [MMCC_KEYS.CARTON_HEIGHT]: "Carton's Height (Optional) 纸箱高度",
    [MMCC_KEYS.CARTON_WIDTH]: "Carton's Width (Optional) (纸箱宽度)",
    [MMCC_KEYS.CARTON_DESCRIPTION]: 'Carton Description 纸箱描述',
    [MMCC_KEYS.NUMBER_OF_CARTONS]: 'Number of Cartons 纸箱数量',
    [MMCC_KEYS.TOTAL_CARTON_VALUE]: 'Total Carton Value 纸箱总价值',
    [MMCC_KEYS.HSCODE]: 'HS Code (Optional) 海关编码',
    [MMCC_KEYS.GRN]: 'GRN required? (Optional) 是否需要收货单 (GRN)?',
    [MMCC_KEYS.RDO]: 'RDO required? (Optional) 是否需要退货单 (RDO)?',
    [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: 'Shipper Order Number (Optional) 出貨人訂單編號',
    [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: 'Requested Piece Tracking Numbers (Optional) 请求的单件追踪编号'
  }
}

// purpose on upload template
export const MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE = {
  en: {
    'Shipper Box ID': MMCC_KEYS.BOX_ID,
    "Box's Weight (Optional)": MMCC_KEYS.BOX_WEIGHT,
    "Box's Length (Optional)": MMCC_KEYS.BOX_LENGTH,
    "Box's Height (Optional)": MMCC_KEYS.BOX_HEIGHT,
    "Box's Width (Optional)": MMCC_KEYS.BOX_WIDTH,
    'Goods Description': MMCC_KEYS.GOODS_DESCRIPTION,
    'No. of Parcels': MMCC_KEYS.NO_OF_PARCELS,
    'Parcel Tracking ID': MMCC_KEYS.PARCEL_TRACKING_ID,
    'To Name': MMCC_KEYS.TO_NAME,
    'To Address': MMCC_KEYS.TO_ADDRESS,
    'To Contact Number': MMCC_KEYS.TO_CONTACT_NUMBER,
    'Item Quantity': MMCC_KEYS.ITEM_QUANTITY,
    'Item Description': MMCC_KEYS.ITEM_DESCRIPTION,
    'Item Value': MMCC_KEYS.ITEM_VALUE,
    Quantity: MMCC_KEYS.QUANTITY,
    'Goods Value': MMCC_KEYS.GOODS_VALUE,
    "Recipient's Name": MMCC_KEYS.RECIPIENT_NAME,
    "Recipient's Phone Number": MMCC_KEYS.RECIPIENT_PHONE_NUMBER,
    "Recipient's Address": MMCC_KEYS.RECIPIENT_ADDRESS,
    "Recipient's City": MMCC_KEYS.RECIPIENT_CITY,
    "Recipient's Postcode": MMCC_KEYS.RECIPIENT_POSTCODE,
    'Requested Tracking Id (Optional)': MMCC_KEYS.REQUESTED_TRACKING_ID,
    "Recipient's State/Province": MMCC_KEYS.RECIPIENT_STATE_PROVINCE,
    "Carton's Weight (Optional)": MMCC_KEYS.CARTON_WEIGHT,
    "Carton's Length (Optional)": MMCC_KEYS.CARTON_LENGTH,
    "Carton's Height (Optional)": MMCC_KEYS.CARTON_HEIGHT,
    "Carton's Width (Optional)": MMCC_KEYS.CARTON_WIDTH,
    'Carton Description': MMCC_KEYS.CARTON_DESCRIPTION,
    'Number of Cartons': MMCC_KEYS.NUMBER_OF_CARTONS,
    'Total Carton Value': MMCC_KEYS.TOTAL_CARTON_VALUE,
    'HS Code (Optional)': MMCC_KEYS.HSCODE,
    'GRN required? (Optional)': MMCC_KEYS.GRN,
    'RDO required? (Optional)': MMCC_KEYS.RDO,
    'Shipper Order Number (Optional)': MMCC_KEYS.SHIPPER_ORDER_NUMBER,
    'Requested Piece Tracking Numbers (Optional)': MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS
  },
  'zh-CN': {
    'Shipper Box ID 自定义箱号': MMCC_KEYS.BOX_ID,
    "Box's Weight (Optional) 箱重（选填）": MMCC_KEYS.BOX_WEIGHT,
    "Box's Length (Optional) 箱长（选填）": MMCC_KEYS.BOX_LENGTH,
    "Box's Height (Optional) 箱高（选填）": MMCC_KEYS.BOX_HEIGHT,
    "Box's Width (Optional) 箱宽（选填）": MMCC_KEYS.BOX_WIDTH,
    'Goods Description 产品描述（英文品名）': MMCC_KEYS.GOODS_DESCRIPTION,
    'No. of Parcels 箱内包裹数': MMCC_KEYS.NO_OF_PARCELS,
    'Parcel Tracking ID 包裹单号': MMCC_KEYS.PARCEL_TRACKING_ID,
    'To Name 包裹收件人姓名（小包裹维度）': MMCC_KEYS.TO_NAME,
    'To Address 包裹收件地址（小包裹维度）': MMCC_KEYS.TO_ADDRESS,
    'To Contact Number 包裹收件人电话（小包裹维度）': MMCC_KEYS.TO_CONTACT_NUMBER,
    'Item Quantity 包裹内产品数量': MMCC_KEYS.ITEM_QUANTITY,
    'Item Description 产品描述': MMCC_KEYS.ITEM_DESCRIPTION,
    'Item Value 包裹内每产品申报单价': MMCC_KEYS.ITEM_VALUE,
    'Quantity of Goods in Box 箱内产品数量': MMCC_KEYS.QUANTITY,
    'Goods Value 申报单价': MMCC_KEYS.GOODS_VALUE,
    "Recipient's Name 收件人姓名（箱子维度）": MMCC_KEYS.RECIPIENT_NAME,
    "Recipient's Phone Number 收件人电话（箱子维度）": MMCC_KEYS.RECIPIENT_PHONE_NUMBER,
    "Recipient's Address 收件地址（箱子维度）": MMCC_KEYS.RECIPIENT_ADDRESS,
    "Recipient's City 收件人所在城市": MMCC_KEYS.RECIPIENT_CITY,
    "Recipient's Postcode 收件人的邮政编码": MMCC_KEYS.RECIPIENT_POSTCODE,
    'Requested Tracking Id (Optional) 请求的追踪编号': MMCC_KEYS.REQUESTED_TRACKING_ID,
    "Recipient's State/Province 收件人所在州/省": MMCC_KEYS.RECIPIENT_STATE_PROVINCE,
    "Carton's Weight (Optional) 纸箱重量": MMCC_KEYS.CARTON_WEIGHT,
    "Carton's Length (Optional) 纸箱长度": MMCC_KEYS.CARTON_LENGTH,
    "Carton's Height (Optional) 纸箱高度": MMCC_KEYS.CARTON_HEIGHT,
    "Carton's Width (Optional) (纸箱宽度)": MMCC_KEYS.CARTON_WIDTH,
    'Carton Description 纸箱描述': MMCC_KEYS.CARTON_DESCRIPTION,
    'Number of Cartons 纸箱数量': MMCC_KEYS.NUMBER_OF_CARTONS,
    'Total Carton Value 纸箱总价值': MMCC_KEYS.TOTAL_CARTON_VALUE,
    'HS Code (Optional) 海关编码': MMCC_KEYS.HSCODE,
    'GRN required? (Optional) 是否需要收货单 (GRN)?': MMCC_KEYS.GRN,
    'RDO required? (Optional) 是否需要退货单 (RDO)?': MMCC_KEYS.RDO,
    'Shipper Order Number (Optional) 出貨人訂單編號': MMCC_KEYS.SHIPPER_ORDER_NUMBER,
    'Requested Piece Tracking Numbers (Optional) 请求的单件追踪编号': MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS
  }
}

export const B2C_FIELD_KEYS = [
  MMCC_KEYS.BOX_ID,
  MMCC_KEYS.RECIPIENT_NAME,
  MMCC_KEYS.RECIPIENT_PHONE_NUMBER,
  MMCC_KEYS.RECIPIENT_ADDRESS,
  MMCC_KEYS.RECIPIENT_CITY,
  MMCC_KEYS.RECIPIENT_POSTCODE,
  MMCC_KEYS.BOX_WEIGHT,
  MMCC_KEYS.BOX_LENGTH,
  MMCC_KEYS.BOX_HEIGHT,
  MMCC_KEYS.BOX_WIDTH,
  MMCC_KEYS.GOODS_DESCRIPTION,
  MMCC_KEYS.NO_OF_PARCELS,
  MMCC_KEYS.PARCEL_TRACKING_ID,
  MMCC_KEYS.TO_NAME,
  MMCC_KEYS.TO_ADDRESS,
  MMCC_KEYS.TO_CONTACT_NUMBER,
  MMCC_KEYS.ITEM_QUANTITY,
  MMCC_KEYS.ITEM_DESCRIPTION,
  MMCC_KEYS.ITEM_VALUE
]

export const B2B_FIELD_KEYS = [
  MMCC_KEYS.BOX_ID,
  MMCC_KEYS.RECIPIENT_NAME,
  MMCC_KEYS.RECIPIENT_PHONE_NUMBER,
  MMCC_KEYS.RECIPIENT_ADDRESS,
  MMCC_KEYS.RECIPIENT_CITY,
  MMCC_KEYS.RECIPIENT_POSTCODE,
  MMCC_KEYS.BOX_WEIGHT,
  MMCC_KEYS.BOX_LENGTH,
  MMCC_KEYS.BOX_HEIGHT,
  MMCC_KEYS.BOX_WIDTH,
  MMCC_KEYS.GOODS_DESCRIPTION,
  MMCC_KEYS.QUANTITY,
  MMCC_KEYS.GOODS_VALUE
]

export const BUNDLE_FIELD_KEYS = [
  MMCC_KEYS.REQUESTED_TRACKING_ID,
  MMCC_KEYS.SHIPPER_ORDER_NUMBER,
  MMCC_KEYS.RECIPIENT_NAME,
  MMCC_KEYS.RECIPIENT_PHONE_NUMBER,
  MMCC_KEYS.RECIPIENT_ADDRESS,
  MMCC_KEYS.RECIPIENT_STATE_PROVINCE,
  MMCC_KEYS.RECIPIENT_CITY,
  MMCC_KEYS.RECIPIENT_POSTCODE,
  MMCC_KEYS.CARTON_WEIGHT,
  MMCC_KEYS.CARTON_LENGTH,
  MMCC_KEYS.CARTON_HEIGHT,
  MMCC_KEYS.CARTON_WIDTH,
  MMCC_KEYS.CARTON_DESCRIPTION,
  MMCC_KEYS.NUMBER_OF_CARTONS,
  MMCC_KEYS.TOTAL_CARTON_VALUE,
  MMCC_KEYS.HSCODE,
  MMCC_KEYS.GRN,
  MMCC_KEYS.RDO,
  MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS
]

export const FLOAT_NUMBER_AND_GREATER_THAN_0 = /^(?!0+(\.0+)?$)\d+(\.\d+)?$/

export const INTEGER_NUMBER_AND_GREATER_THAN_0 = /^[1-9]\d*$/

export const FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000 = /^(?!0\d|0\.0+$|1000)([1-9]\d{0,2}(\.\d+)?|0\.\d*[1-9]\d*)$/

export const FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 = /^(?!0(\.0+)?$)(?:[1-9][0-9]{0,2}(\.\d+)?|1000(\.0+)?)$/

export const B2B_INSTRUCTION_DATA = {
  [MMCC_KEYS.BOX_ID]: 'international.instruction.box_id',
  [MMCC_KEYS.RECIPIENT_NAME]: 'international.instruction.recipient_name',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: 'international.instruction.recipient_phone',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: 'international.instruction.recipient_address',
  [MMCC_KEYS.RECIPIENT_CITY]: 'international.instruction.recipient_city',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: 'international.instruction.recipient_postcode',
  [MMCC_KEYS.BOX_WEIGHT]: 'international.instruction.box_weight',
  [MMCC_KEYS.BOX_LENGTH]: 'international.instruction.box_length',
  [MMCC_KEYS.BOX_HEIGHT]: 'international.instruction.box_height',
  [MMCC_KEYS.BOX_WIDTH]: 'international.instruction.box_width',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'international.instruction.goods_description',
  [MMCC_KEYS.QUANTITY]: 'international.instruction.parcel_quantity',
  [MMCC_KEYS.GOODS_VALUE]: 'international.instruction.parcel_value'
}

export const MMCC_B2B_EXAMPLE_1 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345678',

  [MMCC_KEYS.RECIPIENT_NAME]: 'Louise',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816556',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.QUANTITY]: '10',
  [MMCC_KEYS.GOODS_VALUE]: '100'
}

export const MMCC_B2B_EXAMPLE_2 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345679',

  [MMCC_KEYS.RECIPIENT_NAME]: 'Louise',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816556',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Clothing',
  [MMCC_KEYS.QUANTITY]: '12',
  [MMCC_KEYS.GOODS_VALUE]: '100'
}

export const BUNDLE_EXAMPLE_1 = {
  [MMCC_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
  [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: 'SHIPPER12345678',
  [MMCC_KEYS.RECIPIENT_NAME]: 'Louise',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816556',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: 'Kuala Lumpur',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',
  [MMCC_KEYS.CARTON_WEIGHT]: '22',
  [MMCC_KEYS.CARTON_LENGTH]: '12',
  [MMCC_KEYS.CARTON_HEIGHT]: '12',
  [MMCC_KEYS.CARTON_WIDTH]: '12',
  [MMCC_KEYS.CARTON_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.NUMBER_OF_CARTONS]: '10',
  [MMCC_KEYS.TOTAL_CARTON_VALUE]: '100',
  [MMCC_KEYS.HSCODE]: '620520',
  [MMCC_KEYS.GRN]: 'No',
  [MMCC_KEYS.RDO]: 'No',
  [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: ''
}

export const BUNDLE_EXAMPLE_2 = {
  [MMCC_KEYS.REQUESTED_TRACKING_ID]: 'NJV89804879',
  [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: 'SHIPPER12345678',
  [MMCC_KEYS.RECIPIENT_NAME]: 'David',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816557',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '150, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: 'Kuala Lumpur',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',
  [MMCC_KEYS.CARTON_WEIGHT]: '22',
  [MMCC_KEYS.CARTON_LENGTH]: '12',
  [MMCC_KEYS.CARTON_HEIGHT]: '12',
  [MMCC_KEYS.CARTON_WIDTH]: '12',
  [MMCC_KEYS.CARTON_DESCRIPTION]: 'Clothing',
  [MMCC_KEYS.NUMBER_OF_CARTONS]: '12',
  [MMCC_KEYS.TOTAL_CARTON_VALUE]: '100',
  [MMCC_KEYS.HSCODE]: '620520',
  [MMCC_KEYS.GRN]: 'No',
  [MMCC_KEYS.RDO]: 'No',
  [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: ''
}

export const B2C_INSTRUCTION_DATA = {
  [MMCC_KEYS.BOX_ID]: 'international.instruction.box_id',
  [MMCC_KEYS.RECIPIENT_NAME]: 'international.instruction.recipient_name',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: 'international.instruction.recipient_phone',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: 'international.instruction.recipient_address',
  [MMCC_KEYS.RECIPIENT_CITY]: 'international.instruction.recipient_city',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: 'international.instruction.recipient_postcode',
  [MMCC_KEYS.BOX_WEIGHT]: 'international.instruction.box_weight',
  [MMCC_KEYS.BOX_LENGTH]: 'international.instruction.box_length',
  [MMCC_KEYS.BOX_HEIGHT]: 'international.instruction.box_height',
  [MMCC_KEYS.BOX_WIDTH]: 'international.instruction.box_width',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'international.instruction.goods_description',
  [MMCC_KEYS.NO_OF_PARCELS]: 'international.instruction.no_of_parcels',
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'international.instruction.parcel_tracking_id',
  [MMCC_KEYS.TO_NAME]: 'international.instruction.to_name',
  [MMCC_KEYS.TO_ADDRESS]: 'international.instruction.to_address',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: 'international.instruction.to_contact_number',
  [MMCC_KEYS.ITEM_QUANTITY]: 'international.instruction.item_quantity',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'international.instruction.item_description',
  [MMCC_KEYS.ITEM_VALUE]: 'international.instruction.item_value'
}

export const BUNDLE_INSTRUCTION_DATA = {
  [MMCC_KEYS.REQUESTED_TRACKING_ID]: 'international.instruction.requested_tracking_id',
  [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: 'international.instruction.shipper_order_number',
  [MMCC_KEYS.RECIPIENT_NAME]: 'international.instruction.recipient_name',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: 'international.instruction.recipient_phone',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: 'international.instruction.recipient_address',
  [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: 'international.instruction.recipient_state_province',
  [MMCC_KEYS.RECIPIENT_CITY]: 'international.instruction.recipient_city',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: 'international.instruction.recipient_postcode',
  [MMCC_KEYS.CARTON_WEIGHT]: 'international.instruction.carton_weight',
  [MMCC_KEYS.CARTON_LENGTH]: 'international.instruction.carton_length',
  [MMCC_KEYS.CARTON_HEIGHT]: 'international.instruction.carton_height',
  [MMCC_KEYS.CARTON_WIDTH]: 'international.instruction.caron_width',
  [MMCC_KEYS.CARTON_DESCRIPTION]: 'international.instruction.carton_description',
  [MMCC_KEYS.NUMBER_OF_CARTONS]: 'international.instruction.no_of_carton',
  [MMCC_KEYS.TOTAL_CARTON_VALUE]: 'international.instruction.total_carton_value',
  [MMCC_KEYS.HSCODE]: 'international.instruction.hs_code',
  [MMCC_KEYS.GRN]: 'international.instruction.grn',
  [MMCC_KEYS.RDO]: 'international.instruction.rdo',
  [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: 'international.instruction.requested_piece_tracking_number'
}

export const MMCC_ROW_EXAMPLE_1 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345678',

  [MMCC_KEYS.RECIPIENT_NAME]: 'Louise',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816556',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.NO_OF_PARCELS]: '10',
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234567',
  [MMCC_KEYS.TO_NAME]: 'Louise',
  [MMCC_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816556',
  [MMCC_KEYS.ITEM_QUANTITY]: '1',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Pop socket',
  [MMCC_KEYS.ITEM_VALUE]: '10'
}

export const MMCC_ROW_EXAMPLE_2 = {
  ...MMCC_ROW_EXAMPLE_1,
  [MMCC_KEYS.ITEM_QUANTITY]: '2',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Clipper'
}

export const MMCC_ROW_EXAMPLE_3 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345679',

  [MMCC_KEYS.RECIPIENT_NAME]: 'Louise',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '386816556',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Wilayah Persekuatuan',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50450',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.NO_OF_PARCELS]: '10',
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234567',
  [MMCC_KEYS.TO_NAME]: 'Louise',
  [MMCC_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816556',
  [MMCC_KEYS.ITEM_QUANTITY]: '1',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Pop socket',
  [MMCC_KEYS.ITEM_VALUE]: '10'
}

export const MMCC_ROW_EXAMPLE_4 = {
  ...MMCC_ROW_EXAMPLE_3,
  [MMCC_KEYS.ITEM_QUANTITY]: '2',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Clipper',
  [MMCC_KEYS.ITEM_VALUE]: '10'
}

export const MMCC_ROW_EXAMPLE_5 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345680',

  [MMCC_KEYS.RECIPIENT_NAME]: 'David',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '123456789',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: 'Petronas Twin Tower, Lower Ground (Concourse) Level',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Kuala Lumpur City Centre',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50451',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.NO_OF_PARCELS]: '10',
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234567',
  [MMCC_KEYS.TO_NAME]: 'Louise',
  [MMCC_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816556',
  [MMCC_KEYS.ITEM_QUANTITY]: '3',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
  [MMCC_KEYS.ITEM_VALUE]: '10'
}

export const MMCC_ROW_EXAMPLE_6 = {
  ...MMCC_ROW_EXAMPLE_5,
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234568',
  [MMCC_KEYS.TO_NAME]: 'Louise',
  [MMCC_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816556',
  [MMCC_KEYS.ITEM_QUANTITY]: '3',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Tank top',
  [MMCC_KEYS.ITEM_VALUE]: '12'
}

export const MMCC_ROW_EXAMPLE_7 = {
  ...MMCC_ROW_EXAMPLE_6,
  [MMCC_KEYS.ITEM_QUANTITY]: '1',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Sweaters',
  [MMCC_KEYS.ITEM_VALUE]: '50'
}

export const MMCC_ROW_EXAMPLE_8 = {
  [MMCC_KEYS.BOX_ID]: 'NJV12345681',

  [MMCC_KEYS.RECIPIENT_NAME]: 'David',
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: '123456789',
  [MMCC_KEYS.RECIPIENT_ADDRESS]: 'Petronas Twin Tower, Lower Ground (Concourse) Level',
  [MMCC_KEYS.RECIPIENT_CITY]: 'Kuala Lumpur City Centre',
  [MMCC_KEYS.RECIPIENT_POSTCODE]: '50451',

  [MMCC_KEYS.BOX_WEIGHT]: '22',
  [MMCC_KEYS.BOX_LENGTH]: '12',
  [MMCC_KEYS.BOX_HEIGHT]: '12',
  [MMCC_KEYS.BOX_WIDTH]: '12',
  [MMCC_KEYS.GOODS_DESCRIPTION]: 'Fashion accessorizes',
  [MMCC_KEYS.NO_OF_PARCELS]: '10',
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234567',
  [MMCC_KEYS.TO_NAME]: 'Louise',
  [MMCC_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816556',
  [MMCC_KEYS.ITEM_QUANTITY]: '3',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
  [MMCC_KEYS.ITEM_VALUE]: '10'
}

export const MMCC_ROW_EXAMPLE_9 = {
  ...MMCC_ROW_EXAMPLE_8,
  [MMCC_KEYS.PARCEL_TRACKING_ID]: 'SHIPPER1234568',
  [MMCC_KEYS.TO_NAME]: 'Starley',
  [MMCC_KEYS.TO_ADDRESS]: '150, Jin Ampang, Kuala Lumpur, 50451',
  [MMCC_KEYS.TO_CONTACT_NUMBER]: '386816555',
  [MMCC_KEYS.ITEM_QUANTITY]: '3',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Tank top',
  [MMCC_KEYS.ITEM_VALUE]: '12'
}

export const MMCC_ROW_EXAMPLE_10 = {
  ...MMCC_ROW_EXAMPLE_9,
  [MMCC_KEYS.ITEM_QUANTITY]: '1',
  [MMCC_KEYS.ITEM_DESCRIPTION]: 'Sweaters',
  [MMCC_KEYS.ITEM_VALUE]: '50'
}

export const RECIPIENT_COMPARATION_KEYS = [
  'international.template.header.recipient_name',
  'international.template.header.recipient_phone_number',
  'international.template.header.recipient_address',
  'international.template.header.recipient_city',
  'international.template.header.recipient_postcode'
]

export enum PREVIEW_SOURCE {
  DOCUMENT
}

export enum TRANSLATION_KEY {
  BOXES = 'boxes',
  BUNDLES = 'bundles',
  BOX = 'box',
  RECIPIENTS = 'plural_recipient',
  RECIPIENT = 'single_recipient',
  INTL_CREATION_METHOD = 'international_creation_method',
  FILE = 'singular_file',
  FILES = 'plural_file',
  TOTAL_GOODS_VALUE = 'total_goods_value',
  FILE_SIZE = 'file_size',
  X_BUNDLES_CAPITALIZED = 'x_bundles_capitalized',
  X_RECIPIENTS_CAPITALIZED = 'x_recipients_capitalized',
  X_CARTONS_CAPITALIZED = 'x_cartons_capitalized',
  SERVICE_DETAILS = 'service_details',
  ORDERS = 'orders',
  COMMERCIAL_INVOICE = 'commercial_invoice',
  DELIVERY_ORDER = 'delivery_order',
  ADDITIONAL_DOCUMENT = 'upload_addtional_document',
  REVIEW = 'review'
}

export const DEFAULT_B2B_BUNDLE_FORM_FIELD = {
  id: 0,
  name: '',
  contact: '',
  numberOfCartons: null,
  totalCartonValue: null,
  shipperOrderNumber: '',
  postCode: '',
  state: '',
  city: '',
  addressLine1: '',
  rdoRequired: false,
  grnRequired: false,
  requestedTrackingID: '',
  description: '',
  hsCode: ''
}

export const MMCC_B2B_BOX_FORM_DEFAULT_VALUE = {
  boxNo: 0,
  boxId: undefined,
  description: '',
  quantity: '',
  goodsValue: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  isConfirmed: false
}

export const DEFAULT_MMCC_B2B_FORM_FIELD = {
  id: 0,
  address: null,
  b2bBoxes: {
    [0]: MMCC_B2B_BOX_FORM_DEFAULT_VALUE
  }
}

export const BUNDLE_REQUIRED_OPTIONS = {
  RDO: 'RDO',
  GRN: 'GRN'
}

export const DEFAULT_DIMENSION_UNIT = 'cm'

export const COMMON_MMCC_FORM_FIELDS = {
  DESCRIPTION: 'description',
  WEIGHT: 'weight',
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height'
}

export const B2B_BUNDLE_FORM_FIELDS = {
  NAME: 'name',
  CONTACT: 'contact',
  EMAIL: 'email',
  POSTCODE: 'postCode',
  STATE_PROVINCE: 'stateProvince',
  CITY: 'city',
  NUMBER_OF_CARTONS: 'numberOfCartons',
  TOTAL_CARTON_VALUE: 'totalCartonValue',
  SHIPPER_ORDER_NUMBER: 'shipperOrderNumber',
  HSCODE: 'hsCode',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  REQUESTED_CARTON_TRACKING_NUMBERS: 'requestedCartonTrackingNumbers',
  REQUESTED_TRACKING_ID: 'requestedTrackingID',
  RDO_REQUIRED: 'rdoRequired',
  GRN_REQUIRED: 'grnRequired'
}

export const MMCC_B2B_FORM_FIELDS = {
  SHIPPER_BOX_ID: 'boxId',
  NO_OF_PARCELS: 'quantity',
  GOODS_VALUE: 'goodsValue'
}
