import React from 'react'
import { Col, NVModal, T } from '@nv/react-commons/src/Components'
import { PaddingRow, ShortText, TableHeaderRow } from './styles'
import { Text } from 'components/Text'
import { faEdit } from '@fa-pro-light/faEdit'
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { IconButton } from 'components/IconButton'
import { Tooltip } from 'antd'
import { compact, upperCase } from 'lodash'
import { useIntl } from 'hooks'

const formatNumber = value => value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const BoxTable = ({ orders, onDeleteOrder, onEditOrder }) => {
  const intl = useIntl()

  const editOrder = order => {
    onEditOrder(order)
  }

  const confirmDeleteOrder = order => {
    NVModal.confirm({
      title: intl.formatMessage({ id: 'delete_this_box' }),
      content: intl.formatMessage({ id: 'delete_box_confimation' }),
      cancelText: upperCase(intl.formatMessage({ id: 'cancel' })),
      okText: upperCase(intl.formatMessage({ id: 'delete_box' })),
      onOk: () => onDeleteOrder(order)
    })
  }

  return (
    <>
      <TableHeaderRow>
        <Col span={6}>
          <Text id='shipper_box_id' type='bold' style={{ paddingLeft: 8 }} />
        </Col>
        <Col span={6}>
          <Text id='goods_description' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='no_of_parcels' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='goods_value' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='mmcc_dimension' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='weight_kg' type='bold' />
        </Col>
      </TableHeaderRow>

      {orders.map(order => {
        const formatedGoodsValue = formatNumber(order.goodsValue)
        const formatedWeight = formatNumber(order.weight)
        const formatedQuantity = formatNumber(order.quantity)
        const dimensions = compact([order.length, order.width, order.height]).join(' x ')
        return (
          <>
            <PaddingRow key={order.boxNo}>
              <Col span={6}>
                <Tooltip title={order.boxId} placement='topLeft'>
                  <ShortText>{order.boxId}</ShortText>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip title={order.description} placement='topLeft'>
                  <ShortText>{order.description}</ShortText>
                </Tooltip>
              </Col>
              <Col span={3}>
                <Text>{formatedQuantity}</Text>
              </Col>
              <Col span={3}>
                <Text>{formatedGoodsValue}</Text>
              </Col>
              <Col span={3}>
                <Text>{dimensions}</Text>
              </Col>
              <Col span={3}>
                <div className='flex justify-between'>
                  <Text>{formatedWeight}</Text>
                  <div>
                    <Tooltip title={<T id='edit' />}>
                      <IconButton icon={faEdit} onClick={() => editOrder(order)} />
                    </Tooltip>
                    <Tooltip title={<T id='delete' />}>
                      <IconButton icon={faTrashAlt} onClick={() => confirmDeleteOrder(order)} />
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </PaddingRow>
          </>
        )
      })}
    </>
  )
}

export { BoxTable }
