import React, { useContext, useCallback } from 'react'
import { SupportedDocument } from './SupportedDocument'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { mpTrackAddedCIFile, mpTrackSkipCIFile } from 'containers/FPLMixpanel/helpers'
import { SERVICE_TYPE, SERVICE_TYPE_LABEL_IN_MIXPANEL } from '../FPLOrderCreate/constants'

const CommercialInvoiceStep = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { commercialStatus, commercialFiles, commercialErrors, commercialFileNames, selectedService } = ocState

  const handleSkip = useCallback(() => {
    updateOCState({
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW,
      currentStep:
        selectedService.type === SERVICE_TYPE.B2B_BUNDLE
          ? MMCC_OC_STEP.ADDITIONAL_DOCUMENT
          : MMCC_OC_STEP.DELIVERY_ORDER,
      commercialFiles: [],
      commercialErrors: [],
      commercialFileNames: []
    })
    mpTrackSkipCIFile({ orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type] })
  }, [selectedService])

  const handleEditStep = () => {
    updateOCState({
      commercialStatus: commercialFiles.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
        : SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD
    })
  }

  const trackAddedCommercialInvoiceUploadedFiles = (fileNames: string[], fileInfo) => {
    const extensions = fileNames.map(name => {
      const ext = name.split('.').pop()
      return ext ? ext.toUpperCase() : ''
    })
    mpTrackAddedCIFile({
      fileType: extensions.join(', '),
      fileSize: `${fileInfo.size}${fileInfo.unit}`,
      numberOfFiles: fileInfo.totalFiles,
      orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    })
  }

  const saveFiles = (uploadedResult, fileErrors, fileNames) => {
    updateOCState({
      commercialFiles: uploadedResult,
      commercialErrors: fileErrors,
      commercialFileNames: fileNames,
      commercialStatus: fileErrors.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
        : SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
    })
  }

  const goToNextStep = fileInfo => {
    updateOCState({
      currentStep: selectedService.type === SERVICE_TYPE.B2B_BUNDLE ? MMCC_OC_STEP.ADDITIONAL_DOCUMENT : MMCC_OC_STEP.DELIVERY_ORDER,
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW
    })
    trackAddedCommercialInvoiceUploadedFiles(commercialFileNames, fileInfo)
  }

  const handleReset = () => {
    updateOCState({
      commercialFiles: [],
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      commercialErrors: [],
      commercialFileNames: []
    })
  }

  return (
    <SupportedDocument
      headerIntlKey='commercial_invoice'
      skipButtonIntlKey='skip_upload_commercial_invoice_order'
      status={commercialStatus}
      errors={commercialErrors}
      fileNames={commercialFileNames}
      uploadedFiles={commercialFiles}
      onEditStep={handleEditStep}
      onSave={saveFiles}
      onNext={goToNextStep}
      onReset={handleReset}
      onSkip={handleSkip}
    />
  )
}

export { CommercialInvoiceStep }
