/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Layout, NVError } from '@nv/react-commons/src/Components'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { BreakpointListener } from 'components/BreakpointListener'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { GoogleAdwords } from 'components/GoogleAdwords'
import { GoogleTagManager } from 'components/GoogleTagManager'
import { NavigationRoutes } from 'components/NavigationRoutes'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import sagas from 'services/saga'
import styled from 'styled-components'
import { Colors } from 'themes'
import { getLayoutContent } from 'utils/dom'
import { ROUTES } from './constants'
import PropTypes from 'prop-types'
import { OAUTH_ROUTES } from 'containers/Base/constants'

import { selectIsAuthenticated } from './selectors'
import { withLocation } from '../RouterHOCs'
import OrderTypeModal from '../OrderTypeModal'
import { EKYCModal, EKYCOCDisabledModal, EKYCResubmitModal } from '../EKYC'
import { VerifyPhoneNumberModal, UpdatePhoneNumberModal } from 'containers/VerifyPhoneNumber'
import { SetupAccountModal } from 'containers/SetupAccountModal'
import { AddAccountDetailsModal } from 'containers/AddAccountDetailsModal'
import { AccountUpdateSuccessModal } from 'containers/AccountUpdateSuccessModal'
import OrderTypeModalV2 from 'containers/OrderTypeModal/OrderTypeModalV2'

const { Content } = Layout
const { selector } = SelectorUtils

const AppLayout = styled(Layout)`
  &&& {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    background: ${Colors.whiteTree};
  }
`
const BlankLayout = styled(AppLayout)`
  && {
    justify-content: center;
    align-items: center;
  }
`
const BlankContent = styled(Content)`
  && {
    flex-grow: 0;
  }
`

export class App extends React.Component {
  componentDidMount() {
    this.unlistenHistory = this.restoreScroll(this.props.location)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.unlistenHistory = this.restoreScroll(this.props.location)
    }
  }

  UNSAFE_componentWillUnmount() {
    this.unlistenHistory()
  }

  restoreScroll = location => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    if (location.pathname === ROUTES.HOME) {
      try {
        getLayoutContent().scroll(0, 0)
      } catch (e) {
        // ignored if unsupported
      }
    }
  }

  render() {
    const { intl, isAuthenticated, startup, location } = this.props

    if (!startup.done) {
      return null
    }

    const component =
      isAuthenticated && OAUTH_ROUTES.indexOf(location.pathname) === -1 ? (
        <AppLayout>
          <NavigationRoutes />
        </AppLayout>
      ) : (
        <BlankLayout>
          <BlankContent>
            <NavigationRoutes />
          </BlankContent>
        </BlankLayout>
      )

    return (
      <BreakpointListener>
        <NVError message={intl.formatMessage({ id: 'error' })} />
        <OrderTypeModal /> {/* need to remove after B2B and B2C split*/}
        <OrderTypeModalV2 />
        <EKYCModal />
        <EKYCOCDisabledModal />
        <EKYCResubmitModal />
        <VerifyPhoneNumberModal />
        <UpdatePhoneNumberModal />
        <SetupAccountModal />
        <AddAccountDetailsModal />
        <AccountUpdateSuccessModal />
        <ErrorBoundary>{component}</ErrorBoundary>
        {/* GTM is causing an issue for React Router
         * https://github.com/remix-run/react-router/issues/8822#issuecomment-1112356316
         */}
        <GoogleAdwords />
        {/* TODO: COE-719 Remove the old GTM (T6QMNNL) once it's completely replaced with the new one. */}
        <GoogleTagManager id='T6QMNNL' />
        <GoogleTagManager id='KDKTRSQ' />
      </BreakpointListener>
    )
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  startup: PropTypes.any,
  intl: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  startup: selector('startup')()
})

const withConnect = connect(mapStateToProps)

export default compose(...sagas, injectIntl, withLocation, withConnect)(App)
