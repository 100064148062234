import {
  City,
  PostCode,
  StateProvince
} from 'containers/FPLOrderRequestList/Fields'
import {
  AddressLine1,
  AddressLine2,
  CartonDescription,
  CartonsNumber,
  RecipientEmail,
  RecipientName,
  Height,
  HsCode,
  Length,
  ShipperOrderNumber,
  TotalCartonValue,
  Weight,
  Width,
  RequestedCartonTIDs,
  RequestedTrackingID,
  RDOorGRN,
  InlineDescription,
  NoOfParcel,
  GoodsValue,
  ShipperBoxId,
  RecipientContact,
  RecipientAddress,
  LengthInShort,
  WidthInShort,
  HeightInShort
} from './MMCCFields'
import { B2B_BUNDLE_FORM_FIELDS, COMMON_MMCC_FORM_FIELDS, MMCC_B2B_FORM_FIELDS } from './constants'

export const FORM_FIELDS_BY_SECTION = {
  recipient: [
    [B2B_BUNDLE_FORM_FIELDS.NAME, RecipientName, 8, false], // [component_key, Component, the width of column, customization fields]. Example the width of column: <Col lg={8}>{children}</Col>
    [B2B_BUNDLE_FORM_FIELDS.CONTACT, RecipientContact, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.EMAIL, RecipientEmail, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.NUMBER_OF_CARTONS, CartonsNumber, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.TOTAL_CARTON_VALUE, TotalCartonValue, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.SHIPPER_ORDER_NUMBER, ShipperOrderNumber, 8, false]
  ],
  address: [
    [B2B_BUNDLE_FORM_FIELDS.POSTCODE, PostCode, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.STATE_PROVINCE, StateProvince, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.CITY, City, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.ADDRESS_LINE_1, AddressLine1, 12, false],
    [B2B_BUNDLE_FORM_FIELDS.ADDRESS_LINE_2, AddressLine2, 12, false],
  ],
  carton: [
    [COMMON_MMCC_FORM_FIELDS.WEIGHT, Weight, 6, false],
    [COMMON_MMCC_FORM_FIELDS.LENGTH, Length, 6, false],
    [COMMON_MMCC_FORM_FIELDS.WIDTH, Width, 6, false],
    [COMMON_MMCC_FORM_FIELDS.HEIGHT, Height, 6, false],
    [B2B_BUNDLE_FORM_FIELDS.RDO_REQUIRED, RDOorGRN, 24, false],
    [B2B_BUNDLE_FORM_FIELDS.REQUESTED_TRACKING_ID, RequestedTrackingID, 8, false],
    [null, null, 12],
    [B2B_BUNDLE_FORM_FIELDS.REQUESTED_CARTON_TRACKING_NUMBERS, RequestedCartonTIDs, 8, false],
    [COMMON_MMCC_FORM_FIELDS.DESCRIPTION, CartonDescription, 8, false],
    [B2B_BUNDLE_FORM_FIELDS.HSCODE, HsCode, 8, false]
  ]
}

export const RECIPIENT_MMCC_B2B_FORM_FIELDS = {
  recipient: [
    [B2B_BUNDLE_FORM_FIELDS.NAME, RecipientName, 12, false],
    [B2B_BUNDLE_FORM_FIELDS.CONTACT, RecipientContact, 12, false],
    [B2B_BUNDLE_FORM_FIELDS.ADDRESS_LINE_1, RecipientAddress, 16, false],
    [B2B_BUNDLE_FORM_FIELDS.CITY, City, 4, false],
    [B2B_BUNDLE_FORM_FIELDS.POSTCODE, PostCode, 4, false],
  ],
}

export const BOX_MMCC_B2B_FORM_FIELDS = {
  box: [
    [MMCC_B2B_FORM_FIELDS.SHIPPER_BOX_ID, ShipperBoxId, 6, false],
    [COMMON_MMCC_FORM_FIELDS.DESCRIPTION, InlineDescription, 6, false],
    [MMCC_B2B_FORM_FIELDS.NO_OF_PARCELS, NoOfParcel, 3, false],
    [MMCC_B2B_FORM_FIELDS.GOODS_VALUE, GoodsValue, 3, false],
    [COMMON_MMCC_FORM_FIELDS.LENGTH, LengthInShort, 1, false],
    [COMMON_MMCC_FORM_FIELDS.WIDTH, WidthInShort, 1, false],
    [COMMON_MMCC_FORM_FIELDS.HEIGHT, HeightInShort, 1, false],
    [COMMON_MMCC_FORM_FIELDS.WEIGHT, Weight, 3, false],
  ]
}

export const EIDT_BOX_MMCC_B2B_FORM_FIELDS = {
  box: [
    [MMCC_B2B_FORM_FIELDS.SHIPPER_BOX_ID, ShipperBoxId, 12, false],
    [MMCC_B2B_FORM_FIELDS.NO_OF_PARCELS, NoOfParcel, 6, false],
    [MMCC_B2B_FORM_FIELDS.GOODS_VALUE, GoodsValue, 6, false],
    [COMMON_MMCC_FORM_FIELDS.DESCRIPTION, InlineDescription, 24, false],
    [COMMON_MMCC_FORM_FIELDS.LENGTH, Length, 6, false],
    [COMMON_MMCC_FORM_FIELDS.WIDTH, Width, 6, false],
    [COMMON_MMCC_FORM_FIELDS.HEIGHT, Height, 6, false],
    [COMMON_MMCC_FORM_FIELDS.WEIGHT, Weight, 6, false],
  ]
}
