import React, { useMemo } from 'react'
import { useIntl } from 'hooks'
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { Text } from 'components/Text'
import { NVModal, T } from '@nv/react-commons/src/Components'
import {
  DisabledIcon,
  HoverName,
  InlineDiv,
  ListHeader,
  NoMarginIcon,
  PointerGroup,
  ShortText,
  StyledRollDiv
} from './styles'
import { Tooltip } from 'antd'
import { upperCase } from 'lodash'

export const RecipientInSummary = ({
  orderFormGroup,
  activeGroupId,
  totalRecipient,
  onSelectFormGroup,
  onDeleteFormGroup
}) => {
  const intl = useIntl()

  const confirmDeleteGroup = groupId => {
    NVModal.confirm({
      title: intl.formatMessage({ id: 'delete_this_recipient' }),
      content: intl.formatMessage({ id: 'delete_recipient_confimation' }),
      okText: upperCase(intl.formatMessage({ id: 'delete_recipient' })),
      cancelText: upperCase(intl.formatMessage({ id: 'cancel' })),
      onOk: () => onDeleteFormGroup(groupId)
    })
  }

  const formGroups = useMemo(() => {
    return Object.values(orderFormGroup)
  }, [orderFormGroup])

  return (
    <div style={{ background: 'white', borderRadius: 4 }}>
      <ListHeader>
        <Text id='total_recipient' type='bold' size={16} />
        <Text type='bold' size={16}>
          {totalRecipient}
        </Text>
      </ListHeader>

      <StyledRollDiv>
        {formGroups.map((group: any) => {
          return (
            <PointerGroup key={group.id} active={group.id === activeGroupId}>
              <InlineDiv>
                <HoverName onClick={() => onSelectFormGroup(group.id)} type='bold'>
                  <ShortText>
                    {orderFormGroup[group.id].address?.name || intl.formatMessage({ id: 'new_recipient' })}
                  </ShortText>
                </HoverName>
              </InlineDiv>
              <Tooltip title={<T id='delete' />}>
                <NoMarginIcon icon={faTrashAlt} onClick={() => confirmDeleteGroup(group.id)} />
              </Tooltip>
            </PointerGroup>
          )
        })}
      </StyledRollDiv>
    </div>
  )
}
