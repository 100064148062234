export const ORDER_TYPE_MODAL_PATH = 'select-order-type'

export const ORDER_CREATE_TYPES = {
  REGULAR: 'regular',
  RETURN: 'return',
  NINJA_PACK: 'ninjaPack',
  CORPORATE_AWB: 'corporateAwb'
}

export const RecipientType = {
  B2B: 'b2b',
  B2C: 'b2c'
}
