import styled from 'styled-components'
import { Text } from 'components/Text'
import Button from 'components/Button'
import { Alert } from 'components/Alert'
import { Colors, media } from '@nv/react-commons/src/Themes'
import { Spin } from 'antd'
import { Col, Tooltip, NVInput, Form } from '@nv/react-commons/src/Components'
import { Modal } from 'components/Modal'

const InputGroup = NVInput.Group

export const TableHeaderText = styled(Text).attrs({
  type: 'bold',
  inline: true,
  size: 13
})``

export const StyledButtonPrimary = styled(Button)`
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
  margin: 8px ${({ marginRight = 0 }) => marginRight}px 8px;
`
export const StyledAlert = styled(Alert)`
  && {
    margin-right: 8px;
    white-space: pre-line;
  }
`
export const MiddleColumn = styled(Col)`
  && {
    display: flex;
    align-items: center;
  }
`
export const StyledSpin = styled(Spin)`
  padding-right: 10px;
`
export const StyledSpinContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const FooterModalContainer = styled.div`
  display: flex;
  justify-content: right;
`
export const StyledNewOrderButton = styled(Button).attrs({
  type: 'primary',
  size: 'medium'
})`
  && {
    height: 36px;
  }
`
export const StyledButton = styled(Button)`
  margin: 0 5px;
`

export const StyledToolTip = styled(Tooltip).attrs({
  showTooltip: true,
  placement: 'topLeft'
})`
  color: ${Colors.greyishBrown};
  font-size: 14px;
  && {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const StyledModal = styled(Modal)`
  && {
    .ant-modal-footer {
      height: auto;
    }
  }
`

export const StyledB2BBundleModal = styled(StyledModal)`
  &&& {
    .ant-modal-body {
      padding: 0;
    }
  }
`

export const SectionTitle = styled.div`
  font-weight: 600;
  padding-top: ${({ top = 0 }) => top}px;
`

export const InputGroupStyled = styled(InputGroup)`
  display: flex !important;
  align-items: center !important;
  padding: 6px 0 2px 0 !important;

  .ant-select {
    > div {
      font-size: 12px;
    }
    > input {
      padding-top: 3px;
    }
  }
  .ant-row {
    margin: 0 !important;
  }
`

export const StyledFormItem = styled(Form.Item).attrs({
  colon: false
})`
  && {
    ${media.min.tablet`
      margin-bottom: 4px;
      margin-right: 8px;
    `};
    .ant-form-explain {
      font-size: 11px;
      color: ${Colors.salmon};
    }
    .has-error .ant-select-selection {
      box-shadow: 0 0 6px 0 rgb(255 120 120 / 50%);
      border-color: ${Colors.N100};
    }
    .ant-input-number {
      width: 100%;
    }
    ${({ hidden }) => hidden && 'display: none;'}
  }
`

export const LineTooltip = styled.div`
  padding-top: 12px;
  &:first-child {
    padding-top: 0;
  }
`

export const HintText = styled(Text)`
  font-size: 10px;
  color: ${Colors.greyishBrownTwo};
  line-height: 15px;
`

export const ErrorWrapper = styled.div`
  padding: 10px 20px 20px 28px;
`
