import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { VolumePicker } from 'components/VolumePicker'
import { PICKUP_TYPES } from 'containers/PickupType/constants'

const { Types, Creators } = createActions(
  {
    set: ['key', 'data'],
    merge: ['data'],
    submit: ['payload'],
    transferSuccess: ['uuids'],
    submitAllOrdersInBatch: ['batchId'],
    clear: [],
    clearOrders: [],
    clearOCStates: [],
    cleanup: [],

    // pricing
    calculatePriceRequest: ['isReturnOrder'],
    calculatePriceSuccess: ['data'],
    calculatePriceFailure: ['errors']
  },
  { prefix: 'Base/orderCreate/' }
)

export const orderCreateCreators = Creators
export const orderCreateTypes = Types

const INITIAL_PICKUP_STATE = {
  ocType: '',
  pickupType: PICKUP_TYPES.SCHEDULED,
  pickupAddress: null,
  reservation: null,
  volume: VolumePicker.LESS_THAN_3_PARCELS,
  recipientType: null
}
const INITIAL_MANUAL_ORDERS_STATE = {
  orders: []
}
const INITIAL_UPLOAD_ORDERS_STATE = {
  upload: null,
  deliveryType: '',
  csvMapping: null
}
const INITIAL_BATCH_PROGRESS_STATE = {
  asyncUuids: [],
  status: null,
  batchError: {},
  numberOfCreating: 0,
  numberOfTrial: 0,
  failedOrders: [],
  successOrders: [],
  error: null,
  progress: null
}
const INITIAL_ORDERS_STATE = {
  ...INITIAL_UPLOAD_ORDERS_STATE,
  ...INITIAL_MANUAL_ORDERS_STATE,
  ...INITIAL_BATCH_PROGRESS_STATE,
  error: null
}
const INITIAL_PRICING_STATE = {
  pricing: {
    loading: false,
    data: [],
    errors: []
  }
}

const INITIAL_STATE = fromJS({
  ...INITIAL_PICKUP_STATE,
  ...INITIAL_ORDERS_STATE,
  ...INITIAL_PRICING_STATE,
  orderSource: null
})

const set = (state, { key, data }) => state.merge({ [key]: data })
const merge = (state, { data }) => state.merge(data)
const clear = state => state.merge(INITIAL_STATE)
const clearOrders = state => state.merge(INITIAL_ORDERS_STATE)
const clearOCStates = state => {
  const orders = state.get('orders')
  state = state.merge(INITIAL_ORDERS_STATE).set('orders', orders)
  return state
}
const transferSuccess = (state, { uuids }) => {
  const failed = u => {
    const batchSequenceNumbers =
      Object.values(state.get('batchError').toJS()).map(batchError => batchError.batchSequenceNumber) || []
    return batchSequenceNumbers.includes(u.toJS().batchSequenceNumber)
  }
  return state.merge({
    asyncUuids: state
      .get('asyncUuids')
      .filter(u => !uuids.includes(u))
      .toJS(),
    failedOrders: state
      .get('failedOrders')
      .filter(failed)
      .toJS(),
    successOrders: state.get('successOrders').concat(
      state
        .get('failedOrders')
        .filterNot(failed)
        .toJS()
    )
  })
}
const cleanup = state => {
  return state.remove('orderSource').set('status', null)
}
const calculatePriceRequest = state => state.merge({ pricing: { ...INITIAL_PRICING_STATE, loading: true } })
const calculatePriceSuccess = (state, { data }) => state.merge({ pricing: { ...INITIAL_PRICING_STATE, data } })
const calculatePriceFailure = (state, { errors }) => state.merge({ pricing: { ...INITIAL_PRICING_STATE, errors } })

export const orderCreateReducer = createReducer(INITIAL_STATE, {
  [Types.SET]: set,
  [Types.MERGE]: merge,
  [Types.CLEAR]: clear,
  [Types.CLEANUP]: cleanup,
  [Types.CLEAR_ORDERS]: clearOrders,
  [Types.CLEAR_O_C_STATES]: clearOCStates,
  [Types.TRANSFER_SUCCESS]: transferSuccess,

  [Types.CALCULATE_PRICE_REQUEST]: calculatePriceRequest,
  [Types.CALCULATE_PRICE_SUCCESS]: calculatePriceSuccess,
  [Types.CALCULATE_PRICE_FAILURE]: calculatePriceFailure
})
