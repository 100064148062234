import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Alert } from 'components/Alert'
import { Link } from 'components/Link'
import { Button } from 'components/Button'
import { Card } from 'components/Card'
import { Text } from 'components/Text'
import { CenterText } from 'components/CenterText'
import { RadioGroup } from 'components/RadioGroup'
import { Vspace } from 'components/Vspace'
import { Dropdown, Radio, Row, Icon, Form } from '@nv/react-commons/src/Components'
import { Colors, Fonts, media } from 'themes'
import { Dropzone } from 'components/Dropzone'
import { SelectionFormItem } from 'containers/FPLOrderRequestList/SelectionFormItem'
import { Modal } from 'components/Modal'
import { Menu, Tabs } from 'antd/lib'
import { Grid } from 'react-virtualized'
import { Tag } from 'components/Tag'
import { AutoComplete } from 'components/AutoComplete'

export const StyledAlert = styled(Alert)`
  .ant-alert-message {
    font-weight: bold;
    margin-right: 8px;
  }
`

export const ConfirmButton = styled(Button)`
  && {
    height: 36px;
  }
`

export const StyledLink = styled(Link).attrs({
  color: Colors.cherryRed
})`
  ${Fonts.style.regular};
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
`

export const CustomLink = styled(Link)`
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  display: block;
  margin: 0 auto;
  width: fit-content;
  &[disabled] {
    pointer-events: auto;
    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`

export const StyledText = styled(Text).attrs({
  colors: Colors.warmGrey,
  size: 16,
  type: 'bold'
})`
  margin: 16px 0;
  text-align: center;
`

export const StyledCard = styled(Card)`
  && {
    min-width: 702px;
  }
  cursor: auto !important;
`

export const StyledCustomCard = styled(Card)`
  && {
    margin-top: 8px;
    margin-bottom: 16px;
  }
`

export const StyledOrderCard = styled(StyledCard)`
  && {
    > .ant-card-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

export const OCSubStepWrapper = styled.div`
  &:not(:first-child) {
    padding-top: 16px;
  }
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: solid 1px ${Colors.whiteTree};
  }
`

export const StyledFlexBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const StyledButton = styled(Button).attrs({
  type: 'primary',
  size: 'large'
})``

export const StyledRadioGroup = styled(RadioGroup)`
  .ant-radio-wrapper .ant-radio + span {
    padding: 0 12px;
  }
  margin-bottom: 4px;
`

export const StyledRadio = styled(Radio)`
  && {
    display: flex;
    flex: 1;
    min-width: 0;
    line-height: 16px;
    margin-bottom: 0;
    & > span:last-child {
      width: 100%;
    }
    span.ant-radio + * {
      padding-left: 12px;
    }
  }
`

export const StyledCenterText = styled(CenterText)`
  color: ${({ color }) => color || Colors.warmGrey};
`
export const TextCenteredDiv = styled.div`
  text-align: center;
`
export const StyledTextInline = styled(Text)`
  display: inline-block;
`
export const VspaceWrapper = styled(Vspace)`
  padding-bottom: 16px;
  border-bottom: solid 1px ${Colors.whiteTree};
  margin-bottom: 16px;
`
export const StyledServiceCode = styled.span`
  && {
    font-size: 16px;
    font-weight: normal;
    color: ${Colors.disabled};
  }
`
export const StyledServiceDescription = styled.span`
  && {
    font-size: 16px;
    font-weight: normal;
    color: ${Colors.warmGrey};
  }
`
export const StyledDropzone = styled(Dropzone)`
  && {
    cursor: pointer;
  }
`
export const StyledDropdown = styled(Dropdown)`
  && {
    padding-left: 4px;
  }
`
export const NoWrapRow = styled(Row).attrs({
  type: 'flex'
})`
  && {
    flex-wrap: nowrap;
    flex: 1 0 auto;
    ${media.max.tablet`
    margin-top: 16px;`};
  }
`
export const StyledOrderSummaryWrapper = styled.div`
  min-width: 230px;
  margin-left: 16px;
`
export const StyledSummaryCard = styled(StyledCard)`
  && {
    display: flex;
    flex-direction: column;
    height: 300px;
    > .ant-card-body {
      flex: 1;
      padding: 0;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 4px;
        height: 44px;
      }
    }
    .ant-card-head {
      padding: 0 16px;
    }
  }
`
export const StyledCardHeaderText = styled(Text).attrs({
  type: 'bold'
})`
  ${media.min.tablet`
    font-size: 16px;
  `};
`
export const StyledPreview = styled(({ asCard, ...rest }) => <Row {...rest} />)`
  && {
    padding: 0 16px;
    height: 60px;
    background-color: ${props => (props.selected ? Colors.warningLight : 'white')};
    cursor: ${props => (props.selected ? 'auto' : 'pointer')};
    flex-flow: nowrap;
    ${props =>
      props.asCard &&
      `
    border-radius: 5px;
    padding: 16px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  `};
  }
`
export const StyledIcon = styled(Icon)`
  margin-left: 8px;
`

export const DeleteIcon = styled(StyledIcon)`
  margin-right: 8px;
`
export const RightGroupButton = styled.div`
  display: flex;
  > button:first-child {
    margin-right: 10px;
  }
  justify-content: right;
`
export const StyledForm = styled(Form)`
  margin-top: 16px;
  margin-bottom: 8px;
  .ant-form-item-label label {
    &.ant-form-item-required:before,
    &:after {
      display: none;
    }
  }
`

export const StyledRow = styled(Row).attrs({
  type: 'flex',
  gutter: 8
})`
  && {
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
    ${media.max.mobile`
       display: block;
   `};
  }
`

export const StyledFormItem = styled(Form.Item)`
  flex: 1;
  &&& {
    &.ant-form-item {
      margin-bottom: 0;
    }
  }
`

export const DropdownDescription = styled.div`
  font-size: 12px;
  color: ${Colors.lightGreyBlue};
`

export const EmptyTrackingIdMessage = styled.div`
  text-align: start;
  padding-left: 0.5rem;
  white-space: pre-line;
`

export const ItemButton = styled.button`
  text-align: left;
  padding: 0;
`

export const HeaderOrderStep = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 16px;
  position: relative;
`

export const LeftBlock = styled.div`
  position: absolute;
  left: 24px;
  top: 20px;
`

export const StyledCustomLink = styled(Link)`
  && {
    color: ${Colors.pinkishGrey};
    ${Fonts.style.regular};
    display: flex;
    align-items: center;
  }
`

export const StyledCustomButton = styled(({ isFullWidth, ...rest }) => <Button {...rest} />).attrs({
  size: 'medium'
})`
  && {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
    ${props => props.isFullWidth && 'width: 100%;'};
    ${props => `margin-top: ${props.marginTop || 0}px`};
  }
`

export const StyledSelectionFormItem = styled(SelectionFormItem)`
  width: 200px;
  & {
    .ant-select-selection-selected-value div {
      display: none;
    }
  }
`
export const FullScreenModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 !important;
    background-color: #f0f4f6;
  }
  .ant-modal-header {
    padding-bottom: 5px !important;

    .ant-modal-title {
      padding: 16px;
      background: ${Colors.white};
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
    }
  }
  .ant-modal,
  .ant-modal-content {
    width: 100vw;
    margin: 0;
    top: 0;
    min-height: 600px;
  }
`
export const StyledModalHeader = styled.div`
  && {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    button {
      margin-right: 5%;
    }
  }
`
export const StyledTabs = styled(Tabs)`
  && {
    .ant-tabs-ink-bar {
      display: none !important;
    }

    .ant-tabs-bar {
      padding: 24px 0;
      border: none;
    }

    .ant-tabs-nav-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .ant-tabs-nav .ant-tabs-tab {
      text-align: center;
      margin: 1px 0;
      padding: 4px 16px;
      min-width: 300px;
      background: ${Colors.white};
      border: 1px solid ${Colors.N200};
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      border: 1px solid transparent;
      background: ${Colors.balanceTxtBold};
      color: ${Colors.white};
    }
  }
`
export const TableWrapper = styled.div`
  position: relative;
  overflow: auto;
  flex: 1 1;
  display: flex;
`
export const StyledGridRow = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`
export const StyledStickyLeftGridContainer = styled.div`
  position: absolute;
  left: 0;
  top: ${props => props.top || 0}px;
  flex: 0 0 75px;
  background-color: ${props => props.bg || Colors.tableHeader};
  box-shadow: -3px 10px 10px 0px ${Colors.N700};
  z-index: 10;
`
export const StyledStickyRightGridContainer = styled.div`
  position: absolute;
  right: 0;
  top: ${props => props.top || 0}px;
  flex: 0 0 75px;
  background-color: ${props => props.bg || Colors.tableHeader};
  box-shadow: 3px 10px 10px 0px ${Colors.N700};
  z-index: 10;
`
export const StyledHeaderGrid = styled(Grid)`
  width: 100%;
  overflow: hidden !important;
`
export const StyledSideGrid = styled(Grid)`
  overflow: hidden !important;
`
export const StyledGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`
export const StyledBodyGrid = styled(Grid)`
  background-color: ${Colors.white};
  width: 100%;
`
export const StyledCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  align-items: flex-start;
  text-align: left;
  padding: 0 15px;
  overflow-wrap: anywhere;
  ${props => props.border && `border-top: 1px solid ${Colors.N100};`}
  ${props => props.padding && 'padding-top: 6px; padding-bottom: 6px'}
  ${props => props.bolder && 'font-weight: bold;'}
`
export const TextStyled = styled.div`
  display: -webkit-box;
  max-width: 220px;
  -webkit-line-clamp: ${({ line }) => line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledHeaderTable = styled.div`
  background: #f7f9fa;
  padding: 16px;
  border: 1px solid #e6eaed !important;
  display: flex;
  justify-content: space-between;
  border: 1px solid;
`

export const StyledRowTable = styled.div`
  display: flex;
  padding: 16px 0;
  > div {
    &:first-child {
      width: 200px;
    }
  }
  border-bottom: 1px solid #e6eaed;
  ${({ last }) => last && 'border-bottom: 0'}
`

export const StyledTableContent = styled.div`
  border: 1px solid #e6eaed;
  padding: 0 16px;
`

export const TextCol = styled.div`
  position: relative;
  ${({ first }) => first && 'width: 100px;'}
  ${({ color }) => `color: ${color || Colors.greyishBrown}`}
`

export const TextWrapper = styled.div`
  ${({ color }) => `color: ${color || Colors.greyishBrown}`};
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-width: ${props => (props.isitemised ? '250px' : '60px')};
  margin-right: ${props => (props.isitemised ? '20px' : '0')};
`

export const InvalidToolTipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(20px, -50%);
  color: ${Colors.greyishBrown};
`

export const InlineBlock = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
`

export const StyledRightIcon = styled(Icon)`
  margin-right: 8px;
`

export const StyledStatusText = styled(Text)`
  background: ${({ success }) => (success ? 'rgb(236,249,245)' : 'rgb(255,241,241)')};
  color: ${({ success }) => (success ? 'rgb(70,199,154)' : 'rgb(255,120,120)')};
  padding: 0 4px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
`

export const SeperatedLine = styled.div`
  width: 1px;
  height: 16px;
  background: #cacaca;
`

export const StyledEmptyText = styled(Text)`
  font-style: italic;
  font-weight: bold;
  opacity: 0.5;
`

export const StyledErrorText = styled(Text)`
  color: ${Colors.cherryRed};
  opacity: 0.8;
`
export const Description = styled(Text).attrs({
  color: Colors.pinkishGrey,
  size: 14
})`
  word-break: break-word;
  display: block;
  max-width: 600px;
  min-width: 0;
`

export const FlexBlock = styled.div`
  && {
    display: flex;
    justify-content: ${props => (props.justify ? props.justify : 'space-between')};
    align-items: ${props => (props.align ? props.align : 'initial')};
    color: ${Colors.greyishBrown};

    div.ant-row ant-form-item {
      margin: 0 !important;
    }
  }
`

export const TooltipStyled = styled.div`
  max-height: 300px;
  overflow: auto;
`

export const LongText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const GstNumberStyled = styled.span`
  padding-left: ${({ left = 0 }) => left}px;
`

export const NumberStyled = styled.div`
  text-align: right;
  padding-right: ${({ right }) => right || 0}px;
`
export const StyledGreyText = styled(Text)`
  color: ${Colors.pinkishGrey};
`
export const StyledOptionCard = styled(Card)`
  && {
    margin-bottom: 12px;
    border: solid 1px ${Colors.paleGrey};
    &:hover {
      box-shadow: none !important;
    }

    ${props =>
      props.disabled &&
      css`
        background: ${Colors.whiteTree};
      `};

    .ant-card-head {
      padding: 0;
      min-height: auto;
    }

    .ant-card-head-title {
      padding: 0;
    }
  }
`
export const StyledTextBlock = styled.div`
  flex: 1;
  padding-left: 16px;
`
export const OrderTypeLabel = styled(Text).attrs({
  type: 'bold'
})`
  color: ${props => (props.disabled ? Colors.pinkishGrey : Colors.greyishBrown)};
`
const progress = keyframes`
  from {
    transform: translateX(-150%);
    min-width: 100px;
  }
  to {
    transform: translateX(300%);
    min-width: 500px;
  }
`
const animation = css`
  ${progress} 2s infinite
`
export const StyledVerticalBar = styled.div`
  & {
    background-color: ${Colors.salmonCream};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-width: 100%;
    min-height: 3px;
    position: relative;
    overflow: hidden;
  }

  &:after {
    content: '';
    min-height: 3px;
    position: absolute;
    background: ${Colors.cherryRed};
    transform: translateX(-100%);
    animation: ${animation};
  }

  &:before {
    content: '';
    min-height: 3px;
    position: absolute;
    background: ${Colors.cherryRed};
    transform: translateX(-100%);
    animation: ${animation};
    animation-delay: 1s;
  }
`

export const SpinWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-left: 4px;
`

export const StyledLinkWrapper = styled.div`
  width: 100%;
`

export const StyledTag = styled(Tag)`
  &&& {
    color: ${Colors.cherryRed};
    background: ${Colors.lightCherryRed};
    font-size: 10px;
    border: none;

    ${props => props.disabled && `opacity: 0.6`};
  }
`

export const StyledPhasingOutContent = styled.div`
  background: #fff7f0;
  padding: 16px;
  font-size: 14px;
`

export const StyledBlockRight = styled.div`
  display: fex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
`

export const CenterButtonBlock = styled.div`
  padding-right: 24px;
`

export const StyledOpenableModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-title {
    text-align: center;
  }
`

export const CustomUnderlineLink = styled(Link)`
  color: ${Colors.cherryRed};
  font-size: 12px;
  text-decoration: underline;
  font-weight: normal;
`

export const StyledTextPadding = styled(Text)`
  ${({ left }) => `padding-left: ${left}px`};
`

export const StyledAutoComplete = styled(({ hide, ...rest }) => <AutoComplete {...rest} />).attrs({
  className: 'autocomplete'
})`
  ${props =>
    props.hide
      ? `.ant-input {
       transition: none;
       color: ${Colors.white}
     }`
      : ''};
`

export const StyledMenu = styled(Menu)`
  &&& {
    border-right: 0;

    .ant-menu-submenu-title {
      padding: 0;
      margin-bottom: 12px;
      height: 100%;
    }
  }
`

export const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background: ${Colors.balanceRedLight};
    padding-top: 12px;
  }
`

export const StyledHeader = styled(Row)`
  background: rgb(232, 235, 237);
  padding: 10px 0;
  font-weight: bold;
`

export const StyledRowInTable = styled(Row)`
  padding: 10px 0;
  font-weight: bold;
  border-bottom: 1px solid rgb(232, 235, 237);
`

export const StyledContent = styled(Text)`
  &&& {
    text-align: ${({ position }) => position};
    width: 100%;
    padding-right: 8px;
  }
`

export const StyledCenterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledVerticalLine = styled.div`
  border-right: 1px solid #cacaca;
  height: 10px;
`

export const StyledNotSupportedText = styled(Text)`
  font-size: 10px;
  position: absolute;
  top: 12px;
`

export const StyledEllipsisText = styled(Text)`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
`

export const StyledDivWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledOverlayMenu = styled(Menu)`
  &&& {
    position: absolute;
    right: -250px;
    top: -130px;
  }
  width: auto;
  max-width: 280px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`
