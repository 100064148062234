import React, { useContext, useMemo, useState } from 'react'
import { StyledFlexBlock } from 'containers/FPLOrderCreate/styles'
import { T } from '@nv/react-commons/src/Components'
import { CancelButton, HorizontalSeperateLine } from './styles'
import { BoxTable } from './BoxTable'
import { Vspace } from 'components/Vspace'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import Button from 'components/Button'
import { EditBoxModal } from './EditBoxModal'
import { BOX_MMCC_B2B_FORM_FIELDS } from './FormFields'
import { CreationForm } from './CreationForm'
import { MMCC_B2B_BOX_FORM_DEFAULT_VALUE } from './constants'
import { isEmpty, max } from 'lodash'

const initialState = {
  editBoxNo: null,
  visibleModal: false
}

export const BoxSection = ({ formRef, activeBoxNo, setActiveBoxNo }) => {
  const [state, setState] = useState(initialState)
  const { editBoxNo, visibleModal } = state

  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { activeGroupId, mmccB2BFormGroup, visibleAddBoxForm } = ocState

  const boxes = useMemo(() => {
    return Object.values(mmccB2BFormGroup[activeGroupId].b2bBoxes).filter(box => box.isConfirmed)
  }, [mmccB2BFormGroup, activeGroupId])

  const validateFields = () => {
    formRef.current.validateFields((err) => {
      if (!err) {
        const nextBoxNo = activeBoxNo + 1
        const newOrderGroupBox = {
          ...mmccB2BFormGroup,
          [activeGroupId]: {
            ...mmccB2BFormGroup[activeGroupId],
            b2bBoxes: {
              ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
              [activeBoxNo]: {
                ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
                isConfirmed: true
              },
              [nextBoxNo]: {
                ...MMCC_B2B_BOX_FORM_DEFAULT_VALUE,
                boxNo: nextBoxNo,
              }
            }
          }
        }
        updateOCState({ mmccB2BFormGroup: newOrderGroupBox })
        setActiveBoxNo(nextBoxNo)

      }
    })
  }

  const addNewBoxForm = () => {
    if (!visibleAddBoxForm) {
      const activeNextBoxNo = activeBoxNo + 1
      updateOCState({
        visibleAddBoxForm: true, mmccB2BFormGroup: {
          ...mmccB2BFormGroup,
          [activeGroupId]: {
            ...mmccB2BFormGroup[activeGroupId],
            b2bBoxes: {
              ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
              [activeNextBoxNo]: {
                ...MMCC_B2B_BOX_FORM_DEFAULT_VALUE,
                boxNo: activeNextBoxNo,
              }
            }
          }
        }
      })
      setActiveBoxNo(activeNextBoxNo)
    } else {
      validateFields()
    }
  }

  const deleteOrderInGroup = order => {
    let newMmccB2BFormGroup = { ...mmccB2BFormGroup }
    delete newMmccB2BFormGroup[activeGroupId].b2bBoxes[order.boxNo]
    const lastedBoxNo = max(Object.keys(newMmccB2BFormGroup[activeGroupId].b2bBoxes))
    const newBoxNo = lastedBoxNo ? +lastedBoxNo : 0
    setActiveBoxNo(newBoxNo)
    const emptyBoxAfterDeleting = isEmpty(newMmccB2BFormGroup[activeGroupId].b2bBoxes)

    let showingEditingForm = false
    if (visibleAddBoxForm || emptyBoxAfterDeleting) {
      showingEditingForm = true
    }
    if (emptyBoxAfterDeleting) {
      newMmccB2BFormGroup = {
        ...newMmccB2BFormGroup,
        [activeGroupId]: {
          ...newMmccB2BFormGroup[activeGroupId],
          b2bBoxes: {
            ...newMmccB2BFormGroup[activeGroupId].b2bBoxes,
            [0]: {
              ...MMCC_B2B_BOX_FORM_DEFAULT_VALUE,
              boxNo: 0
            }
          }
        }
      }
    }
    updateOCState({ mmccB2BFormGroup: newMmccB2BFormGroup, visibleAddBoxForm: showingEditingForm })
  }

  const editOrder = order => {
    setState(prevState => ({
      ...prevState,
      editBoxNo: order,
      visibleModal: true
    }))
    // updateOCState({ visibleAddBoxForm: false })
  }

  const cancelAddNewBag = () => {
    const currentActiveBoxIndex = Object.keys(mmccB2BFormGroup[activeGroupId].b2bBoxes).findIndex(boxNo => boxNo == activeBoxNo)
    const previousActiveNo = Object.keys(mmccB2BFormGroup[activeGroupId].b2bBoxes)[currentActiveBoxIndex - 1]

    const newOrderGroupBox = { ...mmccB2BFormGroup }
    delete newOrderGroupBox[activeGroupId].b2bBoxes[activeBoxNo]
    updateOCState({ visibleAddBoxForm: false, mmccB2BFormGroup: newOrderGroupBox })
    setActiveBoxNo(+previousActiveNo)
  }

  const closeModal = () => {
    setState(prevState => ({ ...prevState, visibleModal: false }))
  }

  const saveEditOrder = order => {
    setState(prevState => ({
      ...prevState,
      editBoxNo: null,
      visibleModal: false
    }))
    updateOCState({
      mmccB2BFormGroup: {
        ...mmccB2BFormGroup,
        [activeGroupId]: {
          ...mmccB2BFormGroup[activeGroupId],
          b2bBoxes: {
            ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
            [order.boxNo]: {
              ...mmccB2BFormGroup[activeGroupId].b2bBoxes[order.boxNo],
              ...order,
              isConfirmed: true
            }
          }
        }
      }
    })
  }

  const changeBoxValues = (values) => {
    const newOrderGroupBox = {
      ...mmccB2BFormGroup,
      [activeGroupId]: {
        ...mmccB2BFormGroup[activeGroupId],
        b2bBoxes: {
          ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
          [activeBoxNo]: {
            ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
            ...values
          }
        }
      }
    }
    updateOCState({ mmccB2BFormGroup: newOrderGroupBox })
  }

  return (
    <>
      <BoxTable orders={boxes} onDeleteOrder={deleteOrderInGroup} onEditOrder={editOrder} />

      <Vspace height={4} />
      {boxes.length > 0 && <HorizontalSeperateLine />}
      <Vspace height={14} />
      {visibleAddBoxForm && <CreationForm
        ref={formRef}
        formFields={BOX_MMCC_B2B_FORM_FIELDS}
        dataSource={mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo]}
        onChange={changeBoxValues}
      />}
      <StyledFlexBlock>
        <Button onClick={addNewBoxForm} type='default' size='small'>
          <T id={visibleAddBoxForm ? 'save_and_add_new_box' : 'add_new_box'} allCaps />
        </Button>
        {visibleAddBoxForm && boxes.length > 0 && (
          <CancelButton onClick={cancelAddNewBag} size='small'>
            <T id='cancel' allCaps />
          </CancelButton>
        )}
      </StyledFlexBlock>
      {editBoxNo && (
        <EditBoxModal
          visible={visibleModal}
          order={editBoxNo}
          onDiscard={closeModal}
          onOk={saveEditOrder}
        />
      )}
    </>
  )
}
