import { CsvUtils, ExcelUtils } from '@nv/react-commons/src/Utils'
import { ERROR_CODE_MAPPING, RULE_TYPES, SERVICE_TYPE } from '../FPLOrderCreate/constants'
import { PICKUP_TYPES } from '../PickupType/constants'
import {
  B2BBoxDetail,
  B2BBundleOrderFormGroup,
  B2CBoxDetail,
  BundleDetail,
  Dimension,
  MappedDataKeys,
  RowValueObject,
  UploadedBoxOrder,
  ValidationProps
} from './types'
import { isValidHeader } from '../FPLOrderCreate/dataUtils'
import { COLUMN_TRUNCATION_MAP, ERRORS_TRANSLATION_KEYS, MMCC_VALIDATION_RULES } from './validationRules'
import { DELIVERY_TIME_PARAMS } from 'containers/FPLOrderRequestList/constants'
import { UPLOAD_CSV_OPTION } from 'containers/FPLAllOrders/constants'
import { formatNumber } from 'containers/FPLOrderDetails/dataUtils'
import {
  B2B_FIELD_KEYS,
  B2B_INSTRUCTION_DATA,
  B2C_FIELD_KEYS,
  B2C_INSTRUCTION_DATA,
  BUNDLE_EXAMPLE_1,
  BUNDLE_EXAMPLE_2,
  BUNDLE_FIELD_KEYS,
  BUNDLE_REQUIRED_OPTIONS,
  DEFAULT_DIMENSION_UNIT,
  FLOAT_NUMBER_AND_GREATER_THAN_0,
  FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
  INTEGER_NUMBER_AND_GREATER_THAN_0,
  MMCC_B2B_EXAMPLE_1,
  MMCC_B2B_EXAMPLE_2,
  MMCC_KEYS,
  MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME,
  MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE,
  MMCC_ROW_EXAMPLE_1,
  MMCC_ROW_EXAMPLE_10,
  MMCC_ROW_EXAMPLE_2,
  MMCC_ROW_EXAMPLE_3,
  MMCC_ROW_EXAMPLE_4,
  MMCC_ROW_EXAMPLE_5,
  MMCC_ROW_EXAMPLE_6,
  MMCC_ROW_EXAMPLE_7,
  MMCC_ROW_EXAMPLE_8,
  MMCC_ROW_EXAMPLE_9,
  RECIPIENT_COMPARATION_KEYS,
  UPLOAD_ERROR_TYPE
} from './constants'
import { countBy, endsWith, flatMap, groupBy, isEmpty, lowerCase, map, max, pickBy, round, toNumber, toUpper, trim, uniq, uniqBy } from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { BUNDLE_INSTRUCTION_DATA } from './constants'
import {
  MAX_UPLOAD_ORDER_REQUEST,
  NUMBER_1000,
  NUMBER_255,
  NUMBER_OF_USELESS_LINE,
  requestedCartonsTrackingIDsValidator,
  requestedTrackingIdB2BBundleValidator,
  validateAddress1,
  validateAddress2,
  validateCity,
  validateContact,
  validateEmail,
  validateName,
  validateNumberOfCartons,
  validateOptionalAndFollowingPatternNumber,
  validateOptionalAndLengthOfValue,
  validatePostcode,
  validateRequiredAndFollowingPatternNumber,
  validateRequiredAndLengthOfValue,
  validateShipperOrderNumber,
  validateTotalCartonValues
} from './validatorUtils'
import { DataRow } from './ReviewSimpleTable'
import { MIN_TID_LENGTH } from './validatorUtils'

export const generateMMCCTemplate = (serviceType: number, locale: string, intl) => {
  let instructionFields = {}
  switch (serviceType) {
    case SERVICE_TYPE.MMCCB2C:
      instructionFields = B2C_INSTRUCTION_DATA
      break
    case SERVICE_TYPE.B2B_BUNDLE:
      instructionFields = BUNDLE_INSTRUCTION_DATA
      break
    default:
      instructionFields = B2B_INSTRUCTION_DATA
      break
  }
  const headerFields = Object.keys(instructionFields).map(key => {
    if (MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale]) {
      return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale][key]
    }
    return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME['en'][key]
  })
  const translatorInstructions = Object.values(instructionFields).map(key => intl.formatMessage({ id: key }))
  let exampleData = []
  switch (serviceType) {
    case SERVICE_TYPE.MMCCB2C:
      exampleData = [
        MMCC_ROW_EXAMPLE_1,
        MMCC_ROW_EXAMPLE_2,
        MMCC_ROW_EXAMPLE_3,
        MMCC_ROW_EXAMPLE_4,
        MMCC_ROW_EXAMPLE_5,
        MMCC_ROW_EXAMPLE_6,
        MMCC_ROW_EXAMPLE_7,
        MMCC_ROW_EXAMPLE_8,
        MMCC_ROW_EXAMPLE_9,
        MMCC_ROW_EXAMPLE_10
      ]
      break
    case SERVICE_TYPE.B2B_BUNDLE:
      exampleData = [BUNDLE_EXAMPLE_1, BUNDLE_EXAMPLE_2]
      break
    default:
      exampleData = [MMCC_B2B_EXAMPLE_1, MMCC_B2B_EXAMPLE_2]
      break
  }

  const dataFields = [translatorInstructions, ...exampleData.map(example => Object.values(example))]
  return {
    headerFields: [...headerFields],
    dataFields
  }
}

export const verifyFileExtensionAndTemplate = async (file, serviceType) => {
  const fileName = file?.name
  let results = { data: [], errors: [] }
  const errorMsg = []
  if (endsWith(fileName, '.csv')) {
    results = await CsvUtils.parse(file, UPLOAD_CSV_OPTION)
  }
  if (endsWith(fileName, '.xlsx') || endsWith(fileName, '.xls')) {
    results.data = await ExcelUtils.parseFile(file)
  }
  if (results.errors && results.errors.length !== 0) {
    errorMsg.push({ id: 'international_advanced_search_broken_file' })
  }
  if (results.data) {
    // support both Chinese and English
    const headerFields = (results.data[0] || []).map(key => {
      if (
        MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['zh-CN'] &&
        MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['zh-CN'][key]
      ) {
        return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['zh-CN'][key]
      }
      return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['en'][key]
    })
    const isWrongTemplate = verifyTemplate(headerFields, serviceType)
    if (isWrongTemplate) {
      errorMsg.push({ id: 'international_order_creation_wrong_template' })
    }

    if (results.data.length <= NUMBER_OF_USELESS_LINE) {
      errorMsg.push({ id: 'international_empty_order' })
    }

    if (results.data.length - NUMBER_OF_USELESS_LINE > MAX_UPLOAD_ORDER_REQUEST) {
      errorMsg.push({
        id: 'international_mmcc_maximum_request_row',
        values: { x: formatNumber(MAX_UPLOAD_ORDER_REQUEST) }
      })
    }
    results.data[0] = headerFields
  }
  return {
    fileData: results,
    errorMsg,
    fileName
  }
}

const verifyTemplate = (headerFields, serviceType) => {
  let columnKeys = B2B_INSTRUCTION_DATA
  switch (serviceType) {
    case SERVICE_TYPE.MMCCB2C:
      columnKeys = B2C_INSTRUCTION_DATA
      break
    case SERVICE_TYPE.B2B_BUNDLE:
      columnKeys = BUNDLE_INSTRUCTION_DATA
      break
    default:
      columnKeys = B2B_INSTRUCTION_DATA
  }
  const parcelHeaderKeys = Object.keys(columnKeys)
  const isValidHeaderLength = headerFields.length === parcelHeaderKeys.length
  const isValidHeaderName = isValidHeader(headerFields, parcelHeaderKeys)
  return !isValidHeaderLength || !isValidHeaderName
}

const groupRows = (allOrdersByKey, groupField) => {
  return Object.keys(allOrdersByKey).reduce((results, indexKey) => {
    const fieldValue = allOrdersByKey[indexKey][groupField].value || indexKey
    results[fieldValue] = results[fieldValue] || []
    results[fieldValue].push(indexKey)
    return results
  }, {})
}

const truncateValue = (data, fieldsToTruncate) => {
  return data.map(item => {
    return Object.keys(item).reduce((acc, key) => {
      const trimmedValue = item[key].trim()
      acc[key] =
        fieldsToTruncate[key] && trimmedValue.length > fieldsToTruncate[key]
          ? trimmedValue.substring(0, fieldsToTruncate[key])
          : trimmedValue
      return acc
    }, {})
  })
}

const compareIndices = (data, keys: string[], boxId: string) => {
  keys.forEach(key => {
    const values = Object.values(data).map(row => row[key]?.value)
    if (uniq(values).length !== 1) {
      Object.keys(data).forEach(rowKey => {
        data[rowKey][key].errors.push({ key: 'mismatch_recipient_address', values: [{ x: boxId }] })
        data[rowKey][key].level = UPLOAD_ERROR_TYPE.BAG
        data[rowKey][key].rowIndex = +rowKey + 1
      })
    }
  })
  return data
}

const areAllRowsValid = rows => {
  return Object.values(rows).every(row => Object.values(row).every(field => !field.errors.length))
}

const processB2CRows = (allRowsByIndex: { [key: string]: RowValueObject }) => {
  const groupsByBoxId = groupRows(allRowsByIndex, MMCC_KEYS.BOX_ID)
  const validItemisedOrders = {},
    invalidItemisedOrders = {},
    invalidRows = {},
    validRows = {},
    errorsByBoxLevel = {}
  let numberOfValidItemsInValidOrders = 0,
    numberOfValidItemsInInvalidOrders = 0,
    numberOfInvalidItemsInInvalidOrders = 0

  Object.keys(groupsByBoxId).forEach(boxId => {
    let oneGroup = groupsByBoxId[boxId].reduce((prevRow, currentRowIdx) => {
      return {
        ...prevRow,
        [currentRowIdx]: allRowsByIndex[currentRowIdx]
      }
    }, {})

    let isValid = areAllRowsValid(oneGroup)
    if (isValid) {
      oneGroup = compareIndices(oneGroup, RECIPIENT_COMPARATION_KEYS, boxId)
      isValid = areAllRowsValid(oneGroup)
    }
    if (isValid) {
      validItemisedOrders[boxId] = oneGroup
      numberOfValidItemsInValidOrders += Object.keys(oneGroup).length
      Object.keys(oneGroup).forEach(rowIndex => {
        validRows[rowIndex] = oneGroup[rowIndex]
      })
    } else {
      const bagError = Object.values(oneGroup).some(item => {
        return Object.values(item).some(field => field.level === UPLOAD_ERROR_TYPE.BAG && field.errors.length > 0)
      })
      const parcelError = Object.values(oneGroup).some(item => {
        return Object.values(item).some(field => field.level === UPLOAD_ERROR_TYPE.PARCEL && field.errors.length > 0)
      })
      if (bagError) {
        errorsByBoxLevel[boxId] = oneGroup
      }
      Object.keys(oneGroup).forEach(rowIndex => {
        if (isValid) {
          numberOfValidItemsInInvalidOrders += 1
          validRows[rowIndex] = oneGroup[rowIndex]
        } else {
          numberOfInvalidItemsInInvalidOrders += 1
          invalidRows[rowIndex] = oneGroup[rowIndex]
        }
      })
      invalidItemisedOrders[boxId] = oneGroup
    }
  })

  return {
    validRows,
    invalidRows,
    validItemisedOrders,
    invalidItemisedOrders,
    numberOfValidItemsInValidOrders,
    numberOfValidItemsInInvalidOrders,
    numberOfInvalidItemsInInvalidOrders,
    errorsByBoxLevel
  }
}

const mapRowData = (rows, constantKeys) => {
  return rows.slice(NUMBER_OF_USELESS_LINE).map(row => {
    const rowData: Partial<MappedDataKeys> = {}
    constantKeys.forEach((columnKey: string, index: number) => {
      rowData[columnKey] = row[index] || ''
    })
    return rowData
  })
}

const truncateMappedData = (mappedData: any[]) => {
  return truncateValue(mappedData, COLUMN_TRUNCATION_MAP)
}

const validateRequiredField = (
  columnValue: any,
  isRequired: boolean,
  columnKey: string,
  serviceType: number,
  fieldErrors: { key: string; values?: { [key: string]: string }[] }[]
): { key: string; values?: { [key: string]: string }[] }[] => {
  const errors = [...fieldErrors]
  const isEmptyValue = !columnValue && columnValue !== 0
  if (isRequired && isEmptyValue) {
    if (
      serviceType == SERVICE_TYPE.MMCCB2C ||
      serviceType == SERVICE_TYPE.B2B_BUNDLE ||
      (serviceType == SERVICE_TYPE.MMCCB2B && columnKey !== MMCC_KEYS.BOX_ID)
    ) {
      errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.REQUIRED])
    }
  }
  return errors
}

const applyValidationRules = (
  rules: any[],
  columnValue: any,
  columnKey: string,
  country: string,
  presets: any,
  currentRow: any,
  otherComparedValues: any,
  fieldErrors: { key: string; values?: { [key: string]: string }[] }[]
): { key: string; values?: { [key: string]: string }[] }[] => {
  let errors = [...fieldErrors]
  rules.forEach(rule => {
    const { type, validator } = rule
    switch (type) {
      case RULE_TYPES.MIN_LENGTH:
        if (columnValue?.length <= validator) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX_LENGTH:
        if (columnValue?.length >= validator) {
          errors.push({
            key: ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID].key,
            values: [{
              min: `${MIN_TID_LENGTH}`,
              max: `${rule.validator}`
            }]
          })
        }
        break
      case RULE_TYPES.EQUAL_MAX_LENGTH:
        if (columnValue?.length > validator) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.REGEX:
        if (columnKey === MMCC_KEYS.RECIPIENT_POSTCODE && validator[country]) {
          const countrySpecificValidator = validator[country]
          if (!countrySpecificValidator?.test(columnValue)) {
            errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
          }
        } else if (!validator.test(columnValue)) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.EQUAL_MIN:
        if (columnValue === '' || toNumber(columnValue) <= validator) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MIN:
        if (columnValue === '' || toNumber(columnValue) < validator) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX:
        if (columnValue === '' || toNumber(columnValue) >= validator) {
          errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.DUPLICATE: {
        const trimAndUppercase = value => value?.trim().toUpperCase()
        if (columnKey === MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS) {
          const pieces = columnValue.split(',').map(piece => trimAndUppercase(piece))
          const otherValues = otherComparedValues.map(otherPiece => trimAndUppercase(otherPiece))
          const duplicatedInPieces = Object.keys(pickBy(countBy(pieces), count => count > 1))
          const duplicatedValues = [...duplicatedInPieces, ...pieces.filter(piece => otherValues.includes(piece))]
          if (duplicatedValues.length > 0) {
            errors.push({
              key: ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.DUPLICATE].key,
              values: [{ x: uniq(duplicatedValues).join(', ') }]
            })
          }
        }
        if (columnKey === MMCC_KEYS.REQUESTED_TRACKING_ID) {
          const normalizedPresets = presets.map(item => trimAndUppercase(item))
          const isDuplicate = normalizedPresets.includes(trimAndUppercase(columnValue))
          if (isDuplicate) {
            errors.push({
              key: ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.DUPLICATE].key,
              values: [{ x: columnValue }]
            })
          }
        }
        break
      }
      case RULE_TYPES.EQUAL_LENGTH:
        if (columnKey === MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS && columnValue) {
          const pieces = columnValue.split(',')
          if (pieces.length !== toNumber(currentRow[MMCC_KEYS.NUMBER_OF_CARTONS])) {
            errors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.MATCHING])
          }
        }
        break
    }
  })
  return errors
}

const validateRow = (
  row: any,
  country: string,
  serviceType: number,
  mappedRows: any,
  rowIndex: number
): RowValueObject => {
  const rowValueObject: RowValueObject = {}
  Object.keys(row).forEach(columnKey => {
    const { required, requiredByCountry, rules } = MMCC_VALIDATION_RULES[columnKey]
    const columnValue = row[columnKey]
    let fieldErrors: { key: string; values?: { [key: string]: string }[] }[] = []

    const isRequired = required || requiredByCountry?.includes(country)
    const requiredErrors = validateRequiredField(columnValue, isRequired, columnKey, serviceType, fieldErrors)
    fieldErrors = [...fieldErrors, ...requiredErrors]
    if (isRequired || columnValue) {
      let presets = []
      let otherComparedValues = []
      if (columnKey === MMCC_KEYS.REQUESTED_TRACKING_ID) {
        presets = mappedRows.filter((_, index) => index !== rowIndex).map(d => d[columnKey])
      }
      if (columnKey === MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS) {
        presets = flatMap(mappedRows.map(d => d[columnKey]).map(d => d.split(',')))
        otherComparedValues = flatMap(
          mappedRows
            .filter((_, index) => index !== rowIndex)
            .map(d => d[columnKey])
            .map(d => d.split(','))
        )
      }

      const errors = applyValidationRules(
        rules,
        columnValue,
        columnKey,
        country,
        presets,
        row,
        otherComparedValues,
        fieldErrors
      )
      fieldErrors = [...fieldErrors, ...errors]
    }

    rowValueObject[columnKey] = {
      value: columnValue,
      errors: uniqBy(fieldErrors, error => error?.key)
    }
  })

  return rowValueObject
}

export const validateUploadedData = ({ rows, serviceType, country }: ValidationProps) => {
  let constantKeys = []
  switch (serviceType) {
    case SERVICE_TYPE.MMCCB2C:
      constantKeys = B2C_FIELD_KEYS
      break
    case SERVICE_TYPE.B2B_BUNDLE:
      constantKeys = BUNDLE_FIELD_KEYS
      break
    default:
      constantKeys = B2B_FIELD_KEYS
      break
  }

  const mappedData = mapRowData(rows, constantKeys)
  const mmccOrderMapped = truncateMappedData(mappedData)

  const allRowsByKey: { [key: string]: RowValueObject } = {},
    validRows: { [key: string]: RowValueObject } = {},
    invalidRows: { [key: string]: RowValueObject } = {}

  mmccOrderMapped.forEach((row, rowIndex) => {
    const rowValueObject = validateRow(row, country, serviceType, mmccOrderMapped, rowIndex)
    const firstRowError = Object.values(rowValueObject).find(rowField => rowField.errors.length > 0)
    const rowIndexReflectionFromFile = rowIndex + NUMBER_OF_USELESS_LINE
    if (firstRowError) {
      invalidRows[rowIndexReflectionFromFile] = rowValueObject
    } else {
      validRows[rowIndexReflectionFromFile] = rowValueObject
    }
    allRowsByKey[rowIndexReflectionFromFile] = rowValueObject
  })

  let result = {
    allOrders: allRowsByKey,
    validRows: validRows,
    invalidRows: invalidRows,
    invalidItemisedOrders: invalidRows,
    validItemisedOrders: validRows,
    numberOfValidItemsInValidOrders: null,
    numberOfValidItemsInInvalidOrders: null,
    numberOfInvalidItemsInInvalidOrders: null,
    errorsByBoxLevel: null,
    errorsByParcelLevel: null
  }

  if (serviceType == SERVICE_TYPE.MMCCB2C) {
    const {
      validItemisedOrders,
      invalidItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders,
      errorsByBoxLevel,
      ...rest
    } = processB2CRows(allRowsByKey)
    result = {
      ...result,
      validRows: rest.validRows,
      invalidRows: rest.invalidRows,
      invalidItemisedOrders,
      validItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders,
      errorsByBoxLevel,
      errorsByParcelLevel: invalidRows
    }
  }

  return result
}

export const makeFriendlyError2DArray = (data, intl) => {
  return map(data, row =>
    map(row, item => {
      const formattedErrors = map(item.errors, error => {
        const values = (error.values || []).reduce((err, current) => {
          return { ...err, ...current }
        }, {})
        return intl.formatMessage({ id: error.key }, values)
      })
      return {
        value: item.value,
        errors: formattedErrors
      }
    })
  )
}

export const buildBoxesForB2COrder = (orders: UploadedBoxOrder, currency: string, country: string): B2CBoxDetail[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.values(orders).map((box, index) => {
    const parcelGroupsByTID = groupBy(box, item => item[MMCC_KEYS.PARCEL_TRACKING_ID]?.value)
    const parcelList = Object.keys(parcelGroupsByTID).map(tid => {
      const items = parcelGroupsByTID[tid].map(parcel => {
        return {
          quantity: toNumber(parcel[MMCC_KEYS.ITEM_QUANTITY].value),
          description: parcel[MMCC_KEYS.ITEM_DESCRIPTION].value,
          value: toNumber(parcel[MMCC_KEYS.ITEM_VALUE].value)
        }
      })
      return {
        tracking_id: tid,
        to: {
          name: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_NAME].value,
          address: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_ADDRESS].value,
          contact_number: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_CONTACT_NUMBER].value
        },
        items
      }
    })
    const firstBox = Object.values(parcelGroupsByTID)[0]
    const dimensions: Dimension = {}
    if (firstBox[0][MMCC_KEYS.BOX_LENGTH].value) {
      dimensions.length = toNumber(firstBox[0][MMCC_KEYS.BOX_LENGTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_WIDTH].value) {
      dimensions.width = toNumber(firstBox[0][MMCC_KEYS.BOX_WIDTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_HEIGHT].value) {
      dimensions.height = toNumber(firstBox[0][MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      batch_source_no: index + 1,
      ...(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value && {
        weight: toNumber(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value)
      }),
      ...(!isEmpty(dimensions) && {
        dimensions
      }),
      goods_description: firstBox[0][MMCC_KEYS.GOODS_DESCRIPTION].value,
      quantity: toNumber(firstBox[0][MMCC_KEYS.NO_OF_PARCELS].value),
      customs_currency: currency?.toUpperCase(),
      source_order_id: firstBox[0][MMCC_KEYS.BOX_ID].value,
      parcels: parcelList,
      to: {
        name: firstBox[0][MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: firstBox[0][MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        country_code: country,
        contact_number: firstBox[0][MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: firstBox[0][MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const buildBoxesForB2BOrder = (
  orders: UploadedBoxOrder,
  currency: string,
  toCountryCode: string
): B2BBoxDetail[] => {
  return Object.values(orders).map(box => {
    const dimensions: Dimension = {}
    if (box[MMCC_KEYS.BOX_LENGTH]?.value) {
      dimensions.length = toNumber(box[MMCC_KEYS.BOX_LENGTH].value)
    }
    if (box[MMCC_KEYS.BOX_WIDTH]?.value) {
      dimensions.width = toNumber(box[MMCC_KEYS.BOX_WIDTH].value)
    }
    if (box[MMCC_KEYS.BOX_HEIGHT]?.value) {
      dimensions.height = toNumber(box[MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      parcel_details: {
        customs_currency: currency.toUpperCase(),
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(box[MMCC_KEYS.GOODS_VALUE]?.value),
        ...(box[MMCC_KEYS.BOX_WEIGHT]?.value && {
          weight: toNumber(box[MMCC_KEYS.BOX_WEIGHT].value)
        }),
        customs_description: box[MMCC_KEYS.GOODS_DESCRIPTION]?.value,
        quantity: toNumber(box[MMCC_KEYS.QUANTITY]?.value)
      },
      ...(box[MMCC_KEYS.BOX_ID]?.value && {
        source_order_id: box[MMCC_KEYS.BOX_ID].value
      }),
      to: {
        name: box[MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: box[MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        contact_number: box[MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        country_code: toCountryCode,
        ...(box[MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: box[MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(box[MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: box[MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const buildBoxesForB2BBundleByUploadFile = (
  orders: UploadedBoxOrder,
  currency: string,
  toCountryCode: string
): BundleDetail[] => {
  return Object.values(orders).map(box => {
    const dimensions: Dimension = {}
    if (box[MMCC_KEYS.CARTON_LENGTH]?.value) {
      dimensions.length = toNumber(box[MMCC_KEYS.CARTON_LENGTH].value)
    }
    if (box[MMCC_KEYS.CARTON_WIDTH]?.value) {
      dimensions.width = toNumber(box[MMCC_KEYS.CARTON_WIDTH].value)
    }
    if (box[MMCC_KEYS.CARTON_HEIGHT]?.value) {
      dimensions.height = toNumber(box[MMCC_KEYS.CARTON_HEIGHT].value)
    }
    return {
      source_order_id: box[MMCC_KEYS.SHIPPER_ORDER_NUMBER]?.value,
      requested_tracking_id: box[MMCC_KEYS.REQUESTED_TRACKING_ID]?.value,
      details: {
        customs_currency: currency.toUpperCase(),
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(box[MMCC_KEYS.TOTAL_CARTON_VALUE]?.value),
        ...(box[MMCC_KEYS.CARTON_WEIGHT]?.value && {
          weight: toNumber(box[MMCC_KEYS.CARTON_WEIGHT].value)
        }),
        description: box[MMCC_KEYS.CARTON_DESCRIPTION]?.value,
        quantity: toNumber(box[MMCC_KEYS.NUMBER_OF_CARTONS]?.value),
        ...(box[MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]?.value && {
          requested_piece_tracking_ids: box[MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS].value
            ?.split(',')
            .map(value => value.trim())
        })
      },
      ...(box[MMCC_KEYS.SHIPPER_ORDER_NUMBER]?.value && {
        source_order_id: box[MMCC_KEYS.SHIPPER_ORDER_NUMBER].value
      }),
      to: {
        name: box[MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: box[MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        contact_number: box[MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        country_code: toCountryCode,
        ...(box[MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: box[MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(box[MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: box[MMCC_KEYS.RECIPIENT_POSTCODE].value
        }),
        ...(box[MMCC_KEYS.RECIPIENT_STATE_PROVINCE]?.value && {
          state_province: box[MMCC_KEYS.RECIPIENT_STATE_PROVINCE].value
        })
      }
    }
  })
}

export const aggregateBoxFromUploadFile = (boxOrders, serviceType) => {
  if (!boxOrders) {
    return {
      totalBox: 0,
      totalGoodsValue: 0,
      ...(serviceType === SERVICE_TYPE.B2B_BUNDLE && { totalCartons: 0 })
    }
  }

  const calculateTotalGoodsValue = (orders, getValue) =>
    orders.reduce((total, item) => total + getValue(item), 0)

  let totalGoodsValue = 0
  let totalCartons

  switch (serviceType) {
    case SERVICE_TYPE.MMCCB2C:
      totalGoodsValue = calculateTotalGoodsValue(boxOrders, (box) =>
        box.parcels.reduce(
          (boxTotal, parcel) =>
            boxTotal +
            parcel.items.reduce(
              (parcelTotal, item) => parcelTotal + item.quantity * item.value,
              0
            ),
          0
        )
      )
      break

    case SERVICE_TYPE.MMCCB2B:
      totalGoodsValue = calculateTotalGoodsValue(
        boxOrders,
        (box) => box.parcel_details.value
      )
      break

    case SERVICE_TYPE.B2B_BUNDLE:
      totalGoodsValue = calculateTotalGoodsValue(
        boxOrders,
        (box) => box.details.value
      )
      totalCartons = calculateTotalGoodsValue(
        boxOrders,
        (box) => box.details.quantity
      )
      break

    default:
      break
  }

  return {
    totalBox: boxOrders.length,
    totalGoodsValue,
    ...(serviceType === SERVICE_TYPE.B2B_BUNDLE && { totalCartons })
  }
}

export const transferServiceDetailInfo = ({
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles,
  additionalDocumentFiles,
  serviceType
}) => {
  const { origin_country: originCountry, code } = selectedService

  const from = {
    name: senderAddress.name,
    address_line1: senderAddress.address1,
    country_code: originCountry,
    contact_number: senderAddress.contact,
    ...(senderAddress.city && {
      city: senderAddress.city
    }),
    ...(senderAddress?.email && {
      contact_email: senderAddress.email
    }),
    ...(senderAddress?.address2 && {
      address_line2: senderAddress.address2
    })
  }

  let pickup = null
  const slot = DELIVERY_TIME_PARAMS[+timeSlot?.timeWindow]
  if (pickupType === PICKUP_TYPES.SCHEDULED) {
    pickup = {
      pickup_date: timeSlot.pickupDate,
      pickup_instructions: timeSlot.comments,
      pickup_timeslot: {
        start_time: slot && slot.split('-')[0],
        end_time: slot && slot.split('-')[1]
      },
      pickup_address: {
        name: pickupAddress.name,
        address_line1: pickupAddress.address1,
        country_code: originCountry,
        contact_number: pickupAddress.contact,
        ...(pickupAddress.city && {
          city: pickupAddress.city
        }),
        city: pickupAddress.city,
        ...(pickupAddress?.email && {
          contact_email: pickupAddress.email
        }),
        ...(pickupAddress?.address2 && {
          address_line2: pickupAddress.address2
        })
      }
    }
  }
  // https://jira.ninjavan.net/browse/FPL-6142: Exclude postcode if origin country is not NV countries
  if (COUNTRIES[originCountry]) {
    from['post_code'] = senderAddress.postcode
    if (pickup) {
      pickup.pickup_address['post_code'] = pickupAddress.postcode
    }
  }
  let documents = null
  if (commercialFiles.length) {
    documents = {
      ...documents,
      commercial_invoices: commercialFiles.map(com => ({
        uri: com.data.file_uri,
        name: com.fileName
      }))
    }
  }
  if (additionalDocumentFiles.length) {
    const additionalFiles = additionalDocumentFiles.map(com => ({
      uri: com.data.file_uri,
      name: com.fileName
    }))
    documents = {
      ...documents,
      ...(serviceType === SERVICE_TYPE.B2B_BUNDLE && { additional_files: additionalFiles }),
      ...((serviceType === SERVICE_TYPE.MMCCB2B || serviceType === SERVICE_TYPE.MMCCB2C) && {
        delivery_orders: additionalFiles
      })
    }
  }

  return {
    source: ocMethod,
    service_code: code,
    from,
    ...(pickup && {
      pickup
    }),
    ...(documents && {
      documents
    })
  }
}

export const buildB2CBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })

  return {
    source: ocMethod,
    service_code,
    from,
    ...(pickup && {
      pickup
    }),
    boxes: boxOrders.map(box => ({ ...box, customs_currency: currency })),
    ...(documents && {
      documents
    })
  }
}

export const buildBoxesForB2BOrders = (boxes, recipientAddress, destinationCountry) => {
  return boxes.map(box => {
    const dimensions: Dimension = {}
    if (box.length) {
      dimensions.length = toNumber(box.length)
    }
    if (box.width) {
      dimensions.width = toNumber(box.width)
    }
    if (box.height) {
      dimensions.height = toNumber(box.height)
    }
    return {
      parcel_details: {
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(box.goodsValue),
        ...(box.weight && {
          weight: toNumber(box.weight)
        }),
        customs_description: trim(box.description),
        quantity: toNumber(box.quantity)
      },
      ...(box.boxId && {
        source_order_id: trim(box.boxId)
      }),
      ...(recipientAddress && {
        to: {
          name: trim(recipientAddress.name),
          address_line1: trim(recipientAddress.addressLine1),
          ...(recipientAddress.city && {
            city: trim(recipientAddress.city)
          }),
          ...(recipientAddress.postCode && {
            post_code: trim(recipientAddress.postCode)
          }),
          country_code: destinationCountry,
          contact_number: trim(recipientAddress.contact)
        }
      })
    }
  })
}

export const getCreationBoxFormRules = intl => {
  return {
    boxId: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_shipper_box_id_is_required' })
      },
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      }
    ],
    goodsDescription: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_description_is_required' })
      },
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      }
    ],
    quantity: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_required' })
      },
      {
        pattern: INTEGER_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_invalid' })
      }
    ],
    goodsValue: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_value_is_required' })
      },
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_goods_value_is_invalid' })
      }
    ],
    length: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_bag_length' })
      }
    ],
    width: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_bag_width' })
      }
    ],
    height: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_bag_height' })
      }
    ],
    weight: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_bag_weight_is_invalid' })
      }
    ]
  }
}

export const buildB2BBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })

  const requests = boxOrders.map(d => {
    return {
      source: ocMethod,
      service_code,
      from,
      ...(pickup && {
        pickup
      }),
      parcel_details: {
        ...d.parcel_details,
        customs_currency: toUpper(currency)
      },
      to: d.to,
      ...(d.source_order_id && {
        source_order_id: d.source_order_id
      })
    }
  })
  return {
    order_requests: requests,
    ...(documents && { documents })
  }
}

export const buildB2BBundleRequest = ({
  bundles,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })
  const requests = bundles.map((bundle: B2BBundleOrderFormGroup) => {
    const dimensions = {
      ...(bundle.length && { length: +bundle.length }),
      ...(bundle.width && { width: +bundle.width }),
      ...(bundle.height && { height: +bundle.height })
    }
    const documentsRequired = []
    if (bundle.rdoRequired) {
      documentsRequired.push(BUNDLE_REQUIRED_OPTIONS.RDO)
    }
    if (bundle.grnRequired) {
      documentsRequired.push(BUNDLE_REQUIRED_OPTIONS.GRN)
    }
    let requestedCartonTIDs = []
    if (bundle.requestedCartonTrackingNumbers) {
      requestedCartonTIDs = bundle.requestedCartonTrackingNumbers
        ?.split(',')
        .filter(tid => !isEmpty(tid))
        .map(cartonTid => trim(cartonTid?.toUpperCase()))
    }
    return {
      source: ocMethod,
      service_code,
      from,
      ...(bundle.requestedTrackingID && { requested_tracking_id: bundle.requestedTrackingID?.toUpperCase() }),
      ...(bundle.shipperOrderNumber && { source_order_id: bundle.shipperOrderNumber?.toUpperCase() }),
      ...(pickup && {
        pickup
      }),
      details: {
        origin_country: selectedService.origin_country,
        value: +bundle.totalCartonValue,
        quantity: +bundle.numberOfCartons,
        description: bundle.description,
        customs_currency: toUpper(currency),
        ...(!isEmpty(documentsRequired) && { documents_required: documentsRequired }),
        ...(!isEmpty(requestedCartonTIDs) && { requested_piece_tracking_ids: requestedCartonTIDs }),
        ...(!isEmpty(dimensions) && { dimensions: { ...dimensions, unit: DEFAULT_DIMENSION_UNIT } }),
        ...(bundle.weight && { weight: +bundle.weight }),
        ...(bundle.hsCode && { hs_code: bundle.hsCode })
      },
      to: {
        name: bundle.name,
        address_line1: bundle.addressLine1,
        country_code: selectedService.destination_country,
        contact_number: bundle.contact,
        ...(bundle.email && { contact_email: bundle.email }),
        ...(bundle.stateProvince && { state_province: bundle.stateProvince }),
        ...(bundle.city && { city: bundle.city }),
        ...(bundle.addressLine2 && { address_line2: bundle.addressLine2 }),
        ...(bundle.postCode && { post_code: bundle.postCode })
      }
    }
  })
  return {
    order_requests: requests,
    ...(documents && { documents })
  }
}

export const checkValidSupportedFile = (file, fileIndex) => {
  const errors = []
  if (fileIndex > 1) {
    errors.push('max_file_upload')
  }
  const fileSizeInByMB = file.size / 1024 / 1024
  if (fileSizeInByMB > 50) {
    errors.push('upload_exceed_file_size')
  }
  const isPdf = endsWith(file.name, '.pdf')
  const isCsv = endsWith(file.name, '.csv')
  const isXls = endsWith(file.name, '.xls')
  const isXlsx = endsWith(file.name, '.xlsx')
  const isJpg = endsWith(file.name, '.jpg')
  const isPng = endsWith(file.name, '.png')
  if (!isPdf && !isCsv && !isXls && !isXlsx && !isJpg && !isPng) {
    errors.push('unsupported_file_upload')
  }
  return errors
}

export const calculateFileInfo = data => {
  const totalSizeMB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024 / 1024, 2)
  const tatalSizeKB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024, 2)
  return {
    size: totalSizeMB > 0.01 ? totalSizeMB : tatalSizeKB,
    totalFiles: data.length,
    unit: totalSizeMB > 0.01 ? 'MB' : 'KB'
  }
}

export const sortListWithSelectedFirst = (list, selected) => {
  if (!selected) return list
  return list.sort((a, b) => {
    if (a.id === selected.id) return -1
    if (b.id === selected.id) return 1
    return 0
  })
}

export const validateRecipientInGroup = (address, country) => {
  const lowercaseCountry = lowerCase(country)
  if (!address) {
    return false
  }

  const { name, contact, addressLine1, city, postCode } = address
  if (!validateName(name)) {
    return false
  }
  if (!validateContact(contact)) {
    return false
  }
  if (!validateAddress1(addressLine1)) {
    return false
  }
  if (!validateCity(city, lowercaseCountry)) {
    return false
  }
  if (!validatePostcode(postCode, lowercaseCountry)) {
    return false
  }

  return true
}

export const isValidBundle = (
  bundle: B2BBundleOrderFormGroup,
  country: string,
  inactiveBundles: B2BBundleOrderFormGroup[]
) => {
  const {
    name,
    contact,
    addressLine1,
    city,
    postCode,
    addressLine2,
    email,
    numberOfCartons,
    totalCartonValue,
    shipperOrderNumber,
    weight,
    length,
    width,
    height,
    description,
    hsCode,
    requestedTrackingID,
    requestedCartonTrackingNumbers
  } = bundle
  if (!validateName(name)) {
    return false
  }
  if (!validateContact(contact)) {
    return false
  }
  if (!validateAddress1(addressLine1)) {
    return false
  }
  if (!validateCity(city, lowerCase(country))) {
    return false
  }
  if (!validatePostcode(postCode, lowerCase(country))) {
    return false
  }
  if (!validateAddress2(addressLine2)) {
    return false
  }
  if (!validateEmail(email)) {
    return false
  }
  if (!validateNumberOfCartons(numberOfCartons)) {
    return false
  }
  if (!validateTotalCartonValues(totalCartonValue)) {
    return false
  }
  if (!validateShipperOrderNumber(shipperOrderNumber)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(weight, FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(length, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(width, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(height, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateRequiredAndLengthOfValue(description, NUMBER_1000)) {
    return false
  }
  if (!validateOptionalAndLengthOfValue(hsCode, NUMBER_1000)) {
    return false
  }
  if (requestedTrackingIdB2BBundleValidator(requestedTrackingID, inactiveBundles, '')) {
    return false
  }
  if (requestedCartonsTrackingIDsValidator(requestedCartonTrackingNumbers, numberOfCartons, inactiveBundles, '')) {
    return false
  }
  return true
}

export const isValidB2BForm = (mmccB2BData, country) => {
  const isValidRecipient = validateRecipientInGroup(mmccB2BData.address, country)
  const editingBoxNo = max(Object.keys(mmccB2BData.b2bBoxes))
  const editingBox = mmccB2BData.b2bBoxes[editingBoxNo]
  if (!validateRequiredAndLengthOfValue(editingBox.boxId, NUMBER_255)) {
    return false
  }
  if (!validateRequiredAndLengthOfValue(editingBox.description, NUMBER_255)) {
    return false
  }
  if (!validateRequiredAndFollowingPatternNumber(editingBox.quantity, INTEGER_NUMBER_AND_GREATER_THAN_0)) {
    return false
  }
  if (!validateRequiredAndFollowingPatternNumber(editingBox.goodsValue, FLOAT_NUMBER_AND_GREATER_THAN_0)) {
    return false
  }
  if (
    !validateOptionalAndFollowingPatternNumber(editingBox.weight, FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000)
  ) {
    return false
  }
  if (
    !validateOptionalAndFollowingPatternNumber(editingBox.length, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)
  ) {
    return false
  }
  if (
    !validateOptionalAndFollowingPatternNumber(editingBox.width, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)
  ) {
    return false
  }
  if (
    !validateOptionalAndFollowingPatternNumber(editingBox.height, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)
  ) {
    return false
  }
  return isValidRecipient
}

export const transformErrorsByBoxLevel = (dataSource): DataRow[] => {
  const groupedData = Object.entries(dataSource?.errorsByBoxLevel || {}).reduce<
    Record<string, { value: string; errors?: string[]; rowIndex: number; shipper_box_id: string }[]>
  >((acc, [shipperBoxId, boxes]) => {
    Object.entries(boxes).forEach(([rowIndexStr, row]) => {
      const rowIndex = parseInt(rowIndexStr, 10)
      Object.values(row).forEach(({ value, errors }) => {
        const dataItem = { value, errors, rowIndex, shipper_box_id: shipperBoxId }
        if (!acc[shipperBoxId]) acc[shipperBoxId] = []
        acc[shipperBoxId].push(dataItem)
      })
    })
    return acc
  }, {})

  return Object.entries(groupedData).map(([shipperBoxId, items]) => {
    const rowIndexes = items.map(item => item.rowIndex)
    return {
      index_range: { value: `${Math.min(...rowIndexes) + 1} - ${Math.max(...rowIndexes) + 1}` },
      'international.template.header.shipper_box_id': {
        value: shipperBoxId,
        errors: ['recipient_error_message']
      }
    }
  })
}
