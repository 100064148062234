import React from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, ScrollSync } from 'react-virtualized'
import { Tooltip } from '@nv/react-commons/src/Components'
import {
  FlexBlock,
  StyledBodyGrid,
  StyledCell,
  StyledEmptyText,
  StyledErrorText,
  StyledGridColumn,
  StyledGridRow,
  StyledHeaderGrid,
  StyledSideGrid,
  StyledStickyLeftGridContainer,
  StyledStickyRightGridContainer,
  TextStyled,
  TooltipStyled
} from './styles'
import PropTypes from 'prop-types'
import Colors from 'themes/Colors'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fa-pro-solid/faInfoCircle'
import {
  COL_WIDTH,
  FIRST_COL_WIDTH,
  NUMBER_OF_USELESS_LINE,
  OVER_SCAN_COL_COUNT,
  OVER_SCAN_ROW_COUNT,
  RIGHT_SPACE_WIDTH,
  ROW_HEADER_HEIGHT,
  CELL_PADDING,
  CELL_BORDER_WIDTH,
  MAX_HEIGHT,
  FONT_WEIGHT,
  LINE_HEIGHT,
  FONT_SIZE,
  FONT_FAMILY,
  CELL_MIN_HEIGHT,
  NUMBER_OF_SHOWING_LINE,
  CELL_POSITION,
  COLUMN_INDEX,
  TOOLTIP_LENGTH_SHOWING,
  ITEMISED_COLUMN_INDEX,
  HEIGHT_PERCENT
} from './constants'
import { useIntl } from 'hooks/common'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'

const ReviewOrderTable = ({ dataSource, headerFields, isItemised }) => {
  const intl = useIntl()
  const innerFields = headerFields.slice(NUMBER_OF_USELESS_LINE)
  const width = typeof window !== 'undefined' ? window.innerWidth : 0
  const height = typeof window !== 'undefined' ? window.innerHeight * HEIGHT_PERCENT : 0
  const rowCount = dataSource.length
  const columnCount = innerFields.length
  const cellCache = new CellMeasurerCache({
    defaultWidth: COL_WIDTH,
    fixedWidth: true,
    minHeight: CELL_MIN_HEIGHT
  })

  const calculateCellHeight = ({ rowCells }) => {
    let maxHeight = 0
    rowCells.forEach(({ errors, value }) => {
      const container = document.createElement('div')
      container.style.fontSize = FONT_SIZE
      container.style.fontFamily = FONT_FAMILY
      container.style.lineHeight = LINE_HEIGHT
      container.style.fontWeight = FONT_WEIGHT
      container.style.width = `${COL_WIDTH - CELL_PADDING.LEFT - CELL_PADDING.RIGHT}px`
      const textnode = document.createTextNode(value || 'Empty')
      container.appendChild(textnode)
      if (errors?.length) {
        errors.forEach(error => {
          const errorDiv = document.createElement('div')
          errorDiv.style.fontSize = FONT_SIZE
          errorDiv.style.fontFamily = FONT_FAMILY
          errorDiv.style.lineHeight = LINE_HEIGHT
          errorDiv.style.fontWeight = FONT_WEIGHT
          errorDiv.style.width = `${COL_WIDTH - CELL_PADDING.LEFT - CELL_PADDING.RIGHT}px`
          const preDiv = document.createElement('div')
          preDiv.style.width = '24px'
          preDiv.style.height = '24px'
          preDiv.style.display = 'inline-block'
          errorDiv.appendChild(preDiv)
          const errorTextNode = document.createTextNode(error)
          errorDiv.appendChild(errorTextNode)
          container.appendChild(errorDiv)
        })
      }
      document.body.appendChild(container)
      const currentHeightOfCell = container.offsetHeight
      if (maxHeight < currentHeightOfCell) {
        maxHeight = currentHeightOfCell
      }
      document.body.removeChild(container)
    })

    maxHeight += CELL_PADDING.TOP + CELL_PADDING.BOTTOM + CELL_BORDER_WIDTH
    if (maxHeight < CELL_MIN_HEIGHT) {
      return CELL_MIN_HEIGHT
    }
    const res = maxHeight > MAX_HEIGHT ? MAX_HEIGHT : maxHeight
    return res
  }

  const renderBodyCell = ({ columnIndex, key, parent, rowIndex, style }) => {
    return renderCellMeasurer({
      key,
      style: { ...style, width: COL_WIDTH },
      parent,
      columnIndex,
      rowIndex,
      cache: cellCache,
      position: CELL_POSITION.CENTER,
      value: dataSource[rowIndex][columnIndex + NUMBER_OF_USELESS_LINE]
    })
  }

  const renderHeaderCell = ({ columnIndex, key, style }) => {
    return renderHeader(columnIndex + NUMBER_OF_USELESS_LINE, key, style)
  }

  const renderRightHeaderCell = ({ key, style }) => {
    return renderHeader(1, key, style)
  }

  const renderLeftHeaderCell = ({ key, style }) => {
    return renderHeader(0, key, style)
  }

  const renderLeftSideCell = ({ parent, columnIndex, key, rowIndex, style }) => {
    return renderCellMeasurer({
      key,
      style: { ...style, width: FIRST_COL_WIDTH },
      parent,
      columnIndex,
      rowIndex,
      cache: cellCache,
      position: CELL_POSITION.LEFT,
      value: dataSource[rowIndex][0]
    })
  }

  const renderRightSideCell = ({ key, rowIndex, style, columnIndex }) => {
    return renderCell({
      val: dataSource[rowIndex][1],
      key,
      style: { ...style, width: COL_WIDTH },
      columnIndex,
      rowIndex,
      position: CELL_POSITION.RIGHT
    })
  }

  const renderCellMeasurer = ({ value, key, style, parent, columnIndex, rowIndex, position }) => {
    return (
      <CellMeasurer cache={cellCache} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
        {renderCell({ val: value, key, style, columnIndex, position })}
      </CellMeasurer>
    )
  }

  renderCellMeasurer.propTypes = {
    value: PropTypes.object,
    key: PropTypes.number,
    style: PropTypes.object,
    parent: PropTypes.any,
    columnIndex: PropTypes.number,
    rowIndex: PropTypes.number,
    position: PropTypes.oneOf(CELL_POSITION)
  }

  const renderHeader = (columnIndex, key, style) => {
    const paddingRight = headerFields.length - 1 === columnIndex ? CELL_PADDING.SPECIAL : CELL_PADDING.LEFT
    return (
      <StyledCell key={key} style={{ ...style, paddingRight }} center bolder>
        <FlexBlock justify='initial' align='center'>
          {headerFields[columnIndex]}
          {columnIndex === 0 && <InfoTooltip tooltipTextId='international_row_index_info' placement='left' />}
        </FlexBlock>
      </StyledCell>
    )
  }
  const getShowingLineByColumnIndex = (columnIndex, position) => {
    const COLUMN_CONSTANTS = isItemised ? ITEMISED_COLUMN_INDEX : COLUMN_INDEX
    switch (position) {
      case CELL_POSITION.LEFT:
        return NUMBER_OF_SHOWING_LINE.MIN
      case CELL_POSITION.RIGHT:
        return NUMBER_OF_SHOWING_LINE.NORMAL
      default:
        return COLUMN_CONSTANTS[columnIndex]
    }
  }

  const renderCell = ({ val, key, style, columnIndex, position }) => {
    const value = val?.value
    const errors = val?.errors ?? []
    const paddingRight =
      headerFields.length - 1 === columnIndex + NUMBER_OF_USELESS_LINE ? CELL_PADDING.SPECIAL : CELL_PADDING.LEFT
    const numberOfLineShowing = getShowingLineByColumnIndex(columnIndex, position)
    return (
      <StyledCell key={key} style={{ ...style, paddingRight }} border padding>
        {value ? (
          <Tooltip title={value.length > TOOLTIP_LENGTH_SHOWING && <TooltipStyled>{value}</TooltipStyled>}>
            <TextStyled line={numberOfLineShowing}>{value}</TextStyled>
          </Tooltip>
        ) : (
          <StyledEmptyText>{intl.formatMessage({ id: 'empty' })}</StyledEmptyText>
        )}
        {errors.map(error => (
          <StyledErrorText key={error}>
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
            {error}
          </StyledErrorText>
        ))}
      </StyledCell>
    )
  }

  renderCell.propTypes = {
    val: PropTypes.shape({
      value: PropTypes.any,
      errors: PropTypes.array
    }),
    key: PropTypes.number,
    style: PropTypes.any,
    columnIndex: PropTypes.number,
    position: PropTypes.oneOf(CELL_POSITION)
  }

  return (
    <ScrollSync>
      {({ onScroll, scrollLeft, scrollTop }) => {
        return (
          <StyledGridRow>
            <StyledStickyLeftGridContainer bg={Colors.tableHeader}>
              <StyledHeaderGrid
                cellRenderer={renderLeftHeaderCell}
                width={FIRST_COL_WIDTH}
                height={ROW_HEADER_HEIGHT}
                rowHeight={ROW_HEADER_HEIGHT}
                columnWidth={FIRST_COL_WIDTH}
                rowCount={1}
                columnCount={1}
              />
            </StyledStickyLeftGridContainer>
            <StyledStickyRightGridContainer bg={Colors.tableHeader}>
              <StyledHeaderGrid
                cellRenderer={renderRightHeaderCell}
                width={COL_WIDTH}
                height={ROW_HEADER_HEIGHT}
                rowHeight={ROW_HEADER_HEIGHT}
                columnWidth={COL_WIDTH}
                rowCount={1}
                columnCount={1}
              />
            </StyledStickyRightGridContainer>

            <StyledStickyLeftGridContainer top={ROW_HEADER_HEIGHT} bg={Colors.white}>
              <StyledSideGrid
                overscanColumnCount={OVER_SCAN_COL_COUNT}
                overscanRowCount={OVER_SCAN_ROW_COUNT}
                cellRenderer={renderLeftSideCell}
                columnWidth={FIRST_COL_WIDTH}
                columnCount={1}
                height={height}
                rowHeight={({ index }) => calculateCellHeight({ rowCells: dataSource[index] })}
                rowCount={rowCount}
                scrollTop={scrollTop}
                width={FIRST_COL_WIDTH}
              />
            </StyledStickyLeftGridContainer>
            <StyledStickyRightGridContainer top={ROW_HEADER_HEIGHT} bg={Colors.white}>
              <StyledSideGrid
                overscanColumnCount={OVER_SCAN_COL_COUNT}
                overscanRowCount={OVER_SCAN_ROW_COUNT}
                cellRenderer={renderRightSideCell}
                columnWidth={COL_WIDTH}
                columnCount={1}
                height={height}
                rowHeight={({ index }) => calculateCellHeight({ rowCells: dataSource[index] })}
                rowCount={rowCount}
                scrollTop={scrollTop}
                width={COL_WIDTH}
              />
            </StyledStickyRightGridContainer>

            <StyledGridColumn>
              <AutoSizer disableHeight>
                {() => (
                  <div>
                    <div
                      style={{
                        width,
                        paddingLeft: FIRST_COL_WIDTH,
                        backgroundColor: Colors.tableHeader,
                        height: ROW_HEADER_HEIGHT
                      }}
                    >
                      <StyledHeaderGrid
                        columnWidth={COL_WIDTH}
                        columnCount={columnCount}
                        height={ROW_HEADER_HEIGHT}
                        overscanColumnCount={OVER_SCAN_COL_COUNT}
                        cellRenderer={renderHeaderCell}
                        rowHeight={ROW_HEADER_HEIGHT}
                        rowCount={1}
                        scrollLeft={scrollLeft}
                        width={width - RIGHT_SPACE_WIDTH}
                      />
                    </div>
                    <div
                      style={{
                        paddingLeft: FIRST_COL_WIDTH,
                        backgroundColor: Colors.white,
                        height
                      }}
                    >
                      <StyledBodyGrid
                        columnWidth={COL_WIDTH}
                        columnCount={columnCount}
                        deferredMeasurementCache={cellCache}
                        height={height}
                        onScroll={onScroll}
                        overscanColumnCount={OVER_SCAN_COL_COUNT}
                        overscanRowCount={OVER_SCAN_ROW_COUNT}
                        cellRenderer={renderBodyCell}
                        rowHeight={({ index }) => calculateCellHeight({ rowCells: dataSource[index] })}
                        rowCount={rowCount}
                        width={width - RIGHT_SPACE_WIDTH}
                      />
                    </div>
                  </div>
                )}
              </AutoSizer>
            </StyledGridColumn>
          </StyledGridRow>
        )
      }}
    </ScrollSync>
  )
}

ReviewOrderTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object]))),
  headerFields: PropTypes.arrayOf(PropTypes.string),
  isItemised: PropTypes.bool
}

export { ReviewOrderTable }
