import { COUNTRIES } from '@nv/react-commons/src/Constants'

export const STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const TEMPLATE_TYPE = {
  HUB_SHIPPING_LABEL_OLD: 'Hub Shipping Label',
  HUB_SHIPPING_LABEL_NEW: 'hub_shipping_label_100x150',
  HUB_SHIPPING_LABEL_NO_PARCEL_DESC: 'hub_shipping_label_100x150_no_parcel_desc',
  HUB_SHIPPING_LABEL_NO_COD: 'hub_shipping_label_100x150_no_cod',
}

export const TEMPLATE_MAP = {
  [COUNTRIES.SG]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 2,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 14,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 15,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 16,
  },
  [COUNTRIES.ID]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30002,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 30012,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 30013,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 30014,
  },
  [COUNTRIES.MY]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30016,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 30026,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 30027,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 30028,
  },
  [COUNTRIES.TH]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30030,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 30045,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 30046,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 30047,
  },
  [COUNTRIES.VN]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30052,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 30063,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 30064,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 30065,
  },
  [COUNTRIES.PH]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30067,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: 30076,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: 30077,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: 30078,
  },
  // MM does not have new templates
  [COUNTRIES.MM]: {
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_OLD]: 30084,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NEW]: null,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_PARCEL_DESC]: null,
    [TEMPLATE_TYPE.HUB_SHIPPING_LABEL_NO_COD]: null,
  },
}
