/**
 *
 * ErrorBoundary
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { RewriteFrames } from '@sentry/integrations'
import { ErrorPage } from '../ErrorPage'
import { useSentryUser } from './ErrorBoundary.hooks'
import { datadogRum } from '@datadog/browser-rum'

const { ENVIRONMENT, SENTRY_DSN, RELEASE_VERSION, DD_RUM_SAMPLING_RATE, DD_RUM_REPLAY_SAMPLING_RATE } = __CONFIG__

datadogRum.init({
  applicationId: '896a3ad1-a8d7-4ba8-b969-1d2fa4cc06ca',
  clientToken: 'pubce8920f2cb29dd9f29e80be03d9b7afb',
  site: 'datadoghq.com',
  service: 'dash',
  env: ENVIRONMENT,
  version: RELEASE_VERSION,
  sessionSampleRate: DD_RUM_SAMPLING_RATE,
  sessionReplaySampleRate: DD_RUM_REPLAY_SAMPLING_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
})

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(), new RewriteFrames()],
  /** https://docs.sentry.io/platforms/javascript/configuration/sampling/#sampling-transaction-events
   * If you’re not sure what rate to choose, start with a low value and gradually increase it as you learn
   * more about your traffic patterns and volume.
   */
  tracesSampleRate: 0.1,
  environment: ENVIRONMENT,
  release: RELEASE_VERSION
})

export const ErrorBoundary = ({ children }) => {
  useSentryUser()
  return <Sentry.ErrorBoundary fallback={<ErrorPage type={ErrorPage.BOUNDARY} />}>{children}</Sentry.ErrorBoundary>
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

ErrorBoundary.defaultProps = {
  children: 'ErrorBoundary'
}
