import React from 'react'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import PropTypes from 'prop-types'
import { Colors } from 'themes'
import { Vspace } from 'components/Vspace'
import { StyledBlockPosition, StyledLink, StyledRateCard, StyledRow } from './styles'
import { FileUtils } from '@nv/react-commons/src/Utils'
import { mpTrackDownloadRateCard } from 'containers/FPLMixpanel/helpers'
import Config from 'configs'

const RateRow = ({ row }) => {
  return (
    <StyledRow justify='space-between'>
      <Col span={8}>
        <StyledBlockPosition align='start'>
          <Text size={14} type='bold'>
            {row.lane}
          </Text>
        </StyledBlockPosition>
      </Col>
      <Col span={8}>
        <StyledBlockPosition align='center'>
          <Text size={14} type='bold'>
            {row.fromSla} - {row.toSla} days
          </Text>
        </StyledBlockPosition>
      </Col>
      <Col span={8}>
        <StyledBlockPosition align='end'>
          <Text size={14} type='bold'>
            {row.minPrice} - {row.maxPrice} {row.currency}
          </Text>
        </StyledBlockPosition>
      </Col>
    </StyledRow>
  )
}

export const RateCard = ({ estimationRates, activeSupportedToCountries, activeSupportedFromCountries }) => {
  const downloadRateCard = () => {
    activeSupportedFromCountries.forEach(origin =>
      activeSupportedToCountries.forEach(destination => {
        const fileName = `from-${origin}-to-${destination}`.toLowerCase()
        const estimationLink = `${Config.WEBSITE_URL}/files/estimation-rate/${fileName}.pdf`
        if (process.env.NODE_ENV !== 'test') {
          FileUtils.download(estimationLink, { filename: `${fileName}.pdf` })
        }
      })
    )
    mpTrackDownloadRateCard()
  }

  return (
    <StyledRateCard>
      <Vspace height={24} />
      <Row justify='space-between'>
        <Col span={8}>
          <StyledBlockPosition align='start'>
            <Text color={Colors.lightGreyBalance} size={12}>
              <T id='international_onboarding_lane_label' allCaps />
            </Text>
          </StyledBlockPosition>
        </Col>
        <Col span={8}>
          <StyledBlockPosition align='center'>
            <Text color={Colors.lightGreyBalance} size={12}>
              <T id='international_onboarding_time_label' />
            </Text>
          </StyledBlockPosition>
        </Col>
        <Col span={8}>
          <StyledBlockPosition align='end'>
            <Text color={Colors.lightGreyBalance} size={12}>
              <T id='international_onboarding_estimated_price_label' />
            </Text>
          </StyledBlockPosition>
        </Col>
      </Row>
      {estimationRates.map(row => (
        <RateRow row={row} key={row.lane} />
      ))}
      <Vspace height={12} />
      <StyledBlockPosition align='end'>
        <StyledLink onClick={downloadRateCard}>
          <T id='international_download_rate_card' />
        </StyledLink>
      </StyledBlockPosition>
    </StyledRateCard>
  )
}

RateCard.propTypes = {
  estimationRates: PropTypes.array,
  activeSupportedToCountries: PropTypes.array,
  activeSupportedFromCountries: PropTypes.array
}

RateCard.defaultProps = {
  estimationRates: [],
  activeSupportedToCountries: [],
  activeSupportedFromCountries: []
}
