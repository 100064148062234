import React from 'react'
import { compose } from 'redux'
import { mapValues } from 'lodash'

import { Form, Col } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { RowStyled } from './styles'

const MMCCForm = ({ form, formFields, country, inactiveBundles, customsCurrency, retrievalErrors, onSubmit, children, includePadding }) => {
  const intl = useIntl()
  const renderFieldItem = (config, index) => {
    const [key, Component, lg, disabled, otherDisabled] = config

    if (!key) {
      return <Col lg={lg} span={lg && 24} key={index}></Col>
    }
    return (
      <Col lg={lg} span={lg && 24} key={index}>
        <Component
          form={form}
          intl={intl}
          country={country}
          bundles={inactiveBundles || []}
          customsCurrency={customsCurrency}
          disabled={disabled}
          rdoDisabled={disabled}
          grnDisabled={otherDisabled}
          retrievalErrors={retrievalErrors || {}}
        />
      </Col>
    )
  }

  const submitForm = e => {
    e.preventDefault()
    const { validateFields } = form
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form small onSubmit={submitForm}>
      {Object.keys(formFields).map((key, idx) => (
        <RowStyled includePadding={includePadding} background={idx % 2 === 0 ? 'transparent' : '#F7F9FA'} type='flex' key={key}>
          {formFields[key].map(renderFieldItem)}
        </RowStyled>
      ))}
       {children}
    </Form>
  )
}

const CreationForm = compose(
  Form.create({
    mapPropsToFields: ({ dataSource }) => {
      return mapValues(dataSource, value => Form.createFormField({ value }))
    },
    onValuesChange: (props, values) => {
      props.onChange(values)
    }
  })
)(MMCCForm)

export { CreationForm }
