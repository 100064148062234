import React, { useEffect, useRef, useState } from 'react'
import { T } from '@nv/react-commons/src/Components'
import { Modal } from 'components/Modal'
import { CreationForm } from './CreationForm'
import { EIDT_BOX_MMCC_B2B_FORM_FIELDS } from './FormFields';
import { StyledBlockRight } from 'containers/FPLOrderCreate/styles';
import { CancelButton } from './styles';
import Button from 'components/Button';

export const EditBoxModal = ({ visible, order, onDiscard, onOk }) => {
  const fixFormRef = useRef(null)
  const [boxValues, setBoxValues] = useState(order)

  useEffect(() => {
    if (visible) {
      setBoxValues(order)
    }
  }, [visible, order])

  const changeValues = (values) => {
    setBoxValues({ ...boxValues, ...values})
  }

  const submitForm = () => {
    fixFormRef.current.validateFields((err) => {
      if (!err) {
        onOk(boxValues)
      }
    })
  }

  return (
    <Modal visible={visible} title={<T id='edit_b2b_boxes' />} onCancel={onDiscard}>
      <CreationForm
        ref={fixFormRef}
        formFields={EIDT_BOX_MMCC_B2B_FORM_FIELDS}
        dataSource={boxValues}
        onChange={changeValues}
        onSubmit={submitForm}
      />
      <StyledBlockRight>
        <CancelButton size='medium' onClick={onDiscard}>
          <T id='discard' />
        </CancelButton>
        <Button type='primary' size='medium' onClick={submitForm}>
          <T id='save' />
        </Button>
      </StyledBlockRight>
    </Modal>
  )
}
