/* eslint-disable react/jsx-pascal-case */
/**
 *
 * NavigationRoutes
 *
 */

import { BASE_ROUTES } from 'containers/Base/constants'
import { Sider } from 'components/Sider/Loadable'
import { FPLSider } from 'components/FPLSider/Loadable'
import React from 'react'
import { Route, Routes, useLocation, Outlet, Navigate, matchRoutes } from 'react-router-dom'
import { generatePathWithModal } from 'utils/url'
import Config from 'configs'

import dashApi from 'services/api/dashApi'
import { userCreators } from 'containers/Base/redux'
import { ORDER_CREATE_TYPES } from 'containers/OrderTypeModal/constants'

import { ErrorPage } from 'components/ErrorPage/Loadable'
import CallbackContainer from 'containers/CallbackContainer/Loadable'
import Dashboard from 'containers/Dashboard/Loadable'
import Header from 'containers/Header/Loadable'
import LoginForm from 'containers/LoginFormV1/Loadable'
import Mapping from 'containers/Mapping/Loadable'
import NinjaPackOrderCreate from 'containers/NinjaPackOrderCreate/Loadable'
import OCCompleted from 'containers/OCCompleted/Loadable'
import OCEditError from 'containers/OCEditError/Loadable'
import OCProcess from 'containers/OCProcess/Loadable'
import Order from 'containers/Order/Loadable'
import OrderCreate from 'containers/OrderCreate/Loadable'
import OrderHistory from 'containers/OrderHistory/Loadable'
import OrderReturn from 'containers/OrderReturn/Loadable'
import OrderCorporateManualAWB from 'containers/OrderCorporateManualAWB/Loadable'
import Pickups from 'containers/Pickups/Loadable'
import Reports from 'containers/Reports/Loadable'
import Payments from 'containers/Payments/Loadable'
import ResetPasswordForm from 'containers/ResetPasswordForm/Loadable'
import Settings from 'containers/Settings/Loadable'
import SettingsAccount from 'containers/SettingsAccount/Loadable'
import AccountEntry from 'containers/AccountEntry'
import SettingsAddress from 'containers/SettingsAddress/Loadable'
import SettingsBilling from 'containers/SettingsBilling/Loadable'
import SettingsBillingHistory from 'containers/SettingsBillingHistory/Loadable'
import SettingsIT from 'containers/SettingsIT/Loadable'
import SettingsPayment from 'containers/SettingsPayment/SettingsPayment.Loadable'
import SettingsNotification from 'containers/SettingsNotification/Loadable'
import SettingsOrder from 'containers/SettingsOrder/Loadable'
import ForgotPasswordFormV2 from 'containers/ForgotPasswordFormV2/Loadable'
import Tracking from 'containers/Tracking/Loadable'
import LoginFormV2 from 'containers/LoginFormV2/Loadable'
import SignUpFormV2 from 'containers/SignUpFormV2/Loadable'
import ContactUs from 'containers/ContactUs/Loadable'
import CompleteSignUp from 'containers/CompleteSignUp/Loadable'
import RetailSignUp from 'containers/RetailSignUp/Loadable'
import TermsAndConditions from 'containers/TermsAndConditions'
import FPLOrderCreate from 'containers/FPLOrderCreate/Loadable'
import FPLOrderRequestList from 'containers/FPLOrderRequestList/Loadable'
import FPLBatchHistory from 'containers/FPLBatchHistory/Loadable'
import FPLParcelDetail from 'containers/FPLOrderDetails/Loadable'
import FPLHome from 'containers/FPLHome/Loadable'
import FPLTracking from 'containers/FPLTracking/Loadable'
import FPLSearchResult from 'containers/FPLSearchResult/Loadable'
import FPLOrderBatch from 'containers/FPLOrderBatch/Loadable'
import ShipperSelector from 'containers/ShipperSelector/Loadable'
import ReturnParcels from 'containers/ReturnParcels/Loadable'
import { MappingTypes } from 'containers/Mapping'
import { SignInWrapper } from 'containers/SignInWrapper'
import { RequireAuth } from 'containers/RequireAuth'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from 'containers/Base/selectors'
import { ROUTES } from 'containers/Base/constants'
import FPLOrders from 'containers/FPLAllOrders/Loadable'
import { FPLRegistration } from 'containers/FPLRegistration'
import HomeHelpdesk from 'containers/HomeHelpdesk/HomeHelpdesk.Loadable'
import TicketSummary from 'containers/TicketSummary/TicketSummary.Loadable'
import ShipperSupport from 'containers/ShipperSupport/ShipperSupport.Loadable'
import ShipperSupportSuccess from 'containers/ShipperSupport/ShipperSupportSuccess.loadable'

import { AuthLayout } from 'components/AuthLayout'
import UnifiedLoginForm from 'containers/LoginForm/LoginForm.Loadable'
import LoginVerification from 'containers/LoginPhoneVerificationForm/LoginPhoneVerificationForm.Loadable'
import ForgotPasswordForm from 'containers/ForgotPasswordForm/ForgotPasswordForm.Loadable'
import SignUpWrapper from 'containers/SignUpWrapper/SignUpWrapper.Loadable'
import SignUpForm from 'containers/SignUpForm/SignUpForm.Loadable'
import SignUpVerification from 'containers/SignUpVerification/SignUpVerification.Loadable'
import SignUpPassword from 'containers/SignUpPassword/SignUpPassword.Loadable'
import SignUpProfile from 'containers/SignUpProfile/SignUpProfile.Loadable'
import ComingSoon from 'containers/ComingSoon/ComingSoon.Loadable'
import { useStorage } from 'hooks/useStorage'
import FPLMMCCOrderCreation from 'containers/FPLMMCCOrderCreation'
import FPLMMCCOrderBatch from 'containers/FPLMMCCOrderBatch'
import { ShipperSupportPublicForm } from 'containers/ShipperSupportPublicForm/ShipperSupportPublicForm'
import DeleteAccountSuccess from 'containers/DeleteAccountSuccess'
import { ACCOUNT_DELETION_PATH } from 'containers/DeleteAccountModal'

export const REDIRECT_URL_STORAGE_KEY = 'redirectURL'

const NavigationRoutes = () => {
  const location = useLocation()
  const [redirectUrl] = useStorage(REDIRECT_URL_STORAGE_KEY)
  const returnUrl = redirectUrl || BASE_ROUTES.HOME

  const match = matchRoutes(
    [
      { path: ROUTES.TRACKING },
      { path: ROUTES.FPL_TRACKING },
      { path: ROUTES.FPL_ORDERS },
      { path: ROUTES.RETURN_PARCELS }
    ],
    location.pathname
  )
  const isAuthenticated = useSelector(selectIsAuthenticated())
  const internationalHeaderTitle = matchRoutes([{ path: ROUTES.FPL_ORDERS }], location.pathname) ? 'all_orders' : ''
  const rtsFulfillmentHeaderTitle = matchRoutes([{ path: ROUTES.RETURN_PARCELS }], location.pathname)
    ? 'international_return_parcels'
    : ''

  const shouldUseUnifiedAuthFlow = Config.ENABLE_AUTH_UNIFICATION?.toLowerCase() === 'true'

  const { pathname, search } = location
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const shipperId = searchParams.get('shipper_id')
  const isLoginWithToken = pathname === `/${BASE_ROUTES.LOGIN}` && token && shipperId

  const getAccountProps = ({
    noHeader = false,
    noSider = false,
    isInternationalHeader = false,
    hasInternationalSider = false
  } = {}) => ({
    header: noHeader ? null : (
      <Header
        isInternational={isInternationalHeader}
        showSearch={!match}
        title={isInternationalHeader ? internationalHeaderTitle : rtsFulfillmentHeaderTitle}
      />
    ),
    sider: noSider || location.state?.noSider ? null : hasInternationalSider ? <FPLSider /> : <Sider />
  })

  const shouldRenderUnifiedAuthPages = () => {
    if (isAuthenticated) {
      return <Navigate to={returnUrl} replace />
    } else if (!shouldUseUnifiedAuthFlow) {
      return <Navigate to={isLoginWithToken ? ROUTES.LOGIN + search : ROUTES.LOGIN_V2} replace />
    }
    return (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    )
  }

  return (
    <Routes>
      <Route
        path={BASE_ROUTES.FULL}
        element={
          <RequireAuth>
            <AccountEntry {...getAccountProps({ noSider: true, noHeader: true })} />
          </RequireAuth>
        }
      >
        <Route path={BASE_ROUTES.OC}>
          <Route
            path={generatePathWithModal(':step')}
            element={<OrderCreate orderCreateType={ORDER_CREATE_TYPES.REGULAR} />}
          />
        </Route>
        <Route path={BASE_ROUTES.OC_PACKS}>
          <Route
            path={generatePathWithModal(':step')}
            element={<NinjaPackOrderCreate orderCreateType={ORDER_CREATE_TYPES.NINJA_PACK} />}
          />
        </Route>
        <Route path={BASE_ROUTES.ORDER_CORPORATE_MANUAL_AWB}>
          <Route
            path={generatePathWithModal(':step')}
            element={<OrderCorporateManualAWB orderCreateType={ORDER_CREATE_TYPES.CORPORATE_AWB} />}
          />
        </Route>
        <Route path={BASE_ROUTES.ORDER_RETURN}>
          <Route
            path={generatePathWithModal(':step')}
            element={<OrderReturn orderCreateType={ORDER_CREATE_TYPES.RETURN} />}
          />
        </Route>
        <Route path={generatePathWithModal(BASE_ROUTES.OC_PROCESS)} element={<OCProcess />} />
        <Route path={generatePathWithModal(BASE_ROUTES.OC_EDIT_ERROR)} element={<OCEditError />} />
        <Route path={BASE_ROUTES.MAPPING}>
          <Route path={generatePathWithModal(BASE_ROUTES.MAPPING_NEW)} element={<Mapping type={MappingTypes.New} />} />
          <Route
            path={generatePathWithModal(BASE_ROUTES.MAPPING_EDIT)}
            element={<Mapping type={MappingTypes.Edit} />}
          />
          <Route
            path={generatePathWithModal(BASE_ROUTES.MAPPING_EDIT_ERRORS)}
            element={<Mapping type={MappingTypes.EditErrors} />}
          />
          <Route
            path={generatePathWithModal(BASE_ROUTES.MAPPING_PREVIEW)}
            element={<Mapping type={MappingTypes.Preview} />}
          />
        </Route>
        <Route path={generatePathWithModal(BASE_ROUTES.TERMS_AND_CONDITIONS)} element={<TermsAndConditions />} />
      </Route>
      <Route
        path={BASE_ROUTES.ROOT}
        element={
          shouldUseUnifiedAuthFlow ? (
            <Navigate to={isLoginWithToken ? ROUTES.TOKEN_LOGIN + search : BASE_ROUTES.V2 + search} replace />
          ) : (
            <SignInWrapper>
              <Outlet />
            </SignInWrapper>
          )
        }
      >
        <Route index element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN_V2} />} />
        <Route path={generatePathWithModal(BASE_ROUTES.LOGIN)} element={<LoginForm />} />
        <Route path={generatePathWithModal(BASE_ROUTES.CONTACT_US)} element={<ContactUs />} />
        <Route path={generatePathWithModal(BASE_ROUTES.SIGNUP)} element={<SignUpFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.LOGIN_V2)} element={<LoginFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FORGOT_PASSWORD)} element={<ForgotPasswordFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.COMPLETE_SIGNUP)} element={<CompleteSignUp />} />
        <Route path={generatePathWithModal(BASE_ROUTES.RETAIL_SIGNUP)} element={<RetailSignUp />} />
        <Route path='*' element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN_V2} />} />
      </Route>
      {/*
       * This endpoint was added temporarily to provide access to old auth UI
       * TODO: remove once new auth UI is fully complete
       */}
      <Route
        path={BASE_ROUTES.V1}
        element={
          <SignInWrapper>
            <Outlet />
          </SignInWrapper>
        }
      >
        <Route index element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN_V2} />} />
        <Route path={generatePathWithModal(BASE_ROUTES.LOGIN)} element={<LoginForm />} />
        <Route path={generatePathWithModal(BASE_ROUTES.CONTACT_US)} element={<ContactUs />} />
        <Route path={generatePathWithModal(BASE_ROUTES.SIGNUP)} element={<SignUpFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.LOGIN_V2)} element={<LoginFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FORGOT_PASSWORD)} element={<ForgotPasswordFormV2 />} />
        <Route path={generatePathWithModal(BASE_ROUTES.COMPLETE_SIGNUP)} element={<CompleteSignUp />} />
        <Route path={generatePathWithModal(BASE_ROUTES.RETAIL_SIGNUP)} element={<RetailSignUp />} />
        <Route path='*' element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN_V2} />} />
      </Route>
      <Route path={BASE_ROUTES.V2} element={shouldRenderUnifiedAuthPages()}>
        <Route index element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN + search} />} />
        <Route path={generatePathWithModal(BASE_ROUTES.TOKEN_LOGIN)} element={<LoginForm />} />
        <Route path={generatePathWithModal(BASE_ROUTES.LOGIN)}>
          <Route path='*' element={<UnifiedLoginForm />} />
          <Route path={generatePathWithModal(BASE_ROUTES.VERIFY_PHONE)} element={<LoginVerification />} />
        </Route>
        <Route path={generatePathWithModal(BASE_ROUTES.FORGOT_PASSWORD)} element={<ForgotPasswordForm />} />
        <Route path={generatePathWithModal(BASE_ROUTES.SIGNUP)} element={<SignUpWrapper />}>
          <Route path='*' element={<SignUpForm />} />
          <Route path={generatePathWithModal(BASE_ROUTES.COMING_SOON)} element={<ComingSoon />} />
          <Route path={generatePathWithModal(BASE_ROUTES.VERIFICATION)} element={<SignUpVerification />} />
          <Route path={generatePathWithModal(BASE_ROUTES.PASSWORD)} element={<SignUpPassword />} />
          <Route path={generatePathWithModal(BASE_ROUTES.PROFILE)} element={<SignUpProfile />} />
        </Route>
        <Route path='*' element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN} />} />
      </Route>
      <Route path={BASE_ROUTES.ROOT} element={<AccountEntry {...getAccountProps()} />}>
        <Route path={generatePathWithModal(BASE_ROUTES.RESET_PASSWORD)} element={<ResetPasswordForm />} />
        <Route
          path={generatePathWithModal(BASE_ROUTES.VERIFY_EMAIL_ADDRESS)}
          element={
            <CallbackContainer
              callbackFunc={dashApi.verifyEmailAddress}
              successCallback={dispatch => dispatch(userCreators.getUserRequest())}
              entityKey='verifyEmailAddress'
            />
          }
        />
        <Route
          path={generatePathWithModal(BASE_ROUTES.CONFIRM_LINK_ACCOUNT)}
          element={<CallbackContainer callbackFunc={dashApi.confirmLinkAccount} entityKey='confirmLinkAccount' />}
        />
        <Route path='*' element={<ErrorPage />} />
      </Route>
      <Route
        path={BASE_ROUTES.OAUTH}
        element={
          <SignInWrapper isOauth>
            <Outlet />
          </SignInWrapper>
        }
      >
        <Route path={generatePathWithModal(BASE_ROUTES.OAUTH_LOGIN)} element={<LoginFormV2 isOauth />} />
        <Route
          path={generatePathWithModal(BASE_ROUTES.OAUTH_FORGOT_PASSWORD)}
          element={<ForgotPasswordFormV2 isOauth />}
        />
        <Route path='*' index element={<Navigate replace to={isAuthenticated ? returnUrl : BASE_ROUTES.LOGIN_V2} />} />
      </Route>
      <Route
        path={BASE_ROUTES.HOME}
        element={
          <RequireAuth>
            <AccountEntry {...getAccountProps()} />
          </RequireAuth>
        }
      >
        <Route path='*' index element={<Dashboard />} />
        <Route path={generatePathWithModal(BASE_ROUTES.OC_COMPLETED)} element={<OCCompleted />} />
        <Route path={BASE_ROUTES.TRACKING} element={<Outlet />}>
          <Route path='*' index element={<Tracking searchKey='order' showSearch={false} type='basic' />} />
          <Route path={generatePathWithModal(BASE_ROUTES.TRACKING_SEARCH)} element={<Tracking type='search' />} />
          <Route
            path={generatePathWithModal(BASE_ROUTES.TRACKING_ADVANCED)}
            element={<Tracking searchKey='order' type='advanced' />}
          />
        </Route>
        <Route path={BASE_ROUTES.ORDER}>
          <Route path={generatePathWithModal(':orderId')} element={<Order />} />
        </Route>
        <Route path={generatePathWithModal(BASE_ROUTES.ORDER_HISTORY)} element={<OrderHistory />} />
        <Route path={generatePathWithModal(BASE_ROUTES.PICKUPS)} element={<Pickups />} />
        <Route path={generatePathWithModal(BASE_ROUTES.REPORTS)} element={<Reports />} />
        <Route path={generatePathWithModal(BASE_ROUTES.PAYMENTS)} element={<Payments />} />
        <Route path={BASE_ROUTES.HELP_DESK} element={<Outlet />}>
          <Route index path='*' element={<HomeHelpdesk />} />
          <Route
            index
            path={generatePathWithModal(ROUTES.TICKET_SUMMARY + '/:ticketId')}
            element={
              <RequireAuth>
                <TicketSummary />
              </RequireAuth>
            }
          />
          <Route
            index
            path={generatePathWithModal(ROUTES.TICKET_SUMMARY) + '/redirect'}
            element={
              <RequireAuth>
                <TicketSummary />
              </RequireAuth>
            }
          />
          <Route
            index
            path={generatePathWithModal(ROUTES.TICKET_SUMMARY)}
            element={
              <RequireAuth>
                <TicketSummary />
              </RequireAuth>
            }
          />
          <Route path={generatePathWithModal(BASE_ROUTES.SHIPPER_SUPPORT)} element={<ShipperSupport />} />
          <Route
            path={generatePathWithModal(`${BASE_ROUTES.SHIPPER_SUPPORT}/${BASE_ROUTES.SHIPPER_SUPPORT_SUCCESS}`)}
            element={<ShipperSupportSuccess />}
          />
        </Route>
        <Route path={BASE_ROUTES.SETTINGS} element={<Outlet />}>
          <Route path='*' index element={<Settings />} />
          <Route path={generatePathWithModal(BASE_ROUTES.ADDRESS)} element={<SettingsAddress />} />
          <Route path={generatePathWithModal(BASE_ROUTES.ACCOUNT)} element={<Outlet />}>
            <Route index path='*' element={<SettingsAccount />} />
            <Route
              path={generatePathWithModal(`${ACCOUNT_DELETION_PATH}/${BASE_ROUTES.DELETE_ACCOUNT_SUCCESS}`)}
              element={<DeleteAccountSuccess />}
            />
          </Route>
          <Route path={generatePathWithModal(BASE_ROUTES.PAYMENT)} element={<SettingsPayment />} />
          <Route path={generatePathWithModal(BASE_ROUTES.S_ORDER)} element={<SettingsOrder />} />
          <Route path={generatePathWithModal(BASE_ROUTES.NOTIFICATION)} element={<SettingsNotification />} />
          <Route path={generatePathWithModal(BASE_ROUTES.IT)} element={<SettingsIT />} />
          <Route path={BASE_ROUTES.BILLING} element={<Outlet />}>
            <Route index element={<SettingsBilling />} />
            <Route path={generatePathWithModal(BASE_ROUTES.BILLING_HISTORY)} element={<SettingsBillingHistory />} />
          </Route>
        </Route>
        <Route path={generatePathWithModal(BASE_ROUTES.RETURN_PARCELS)} element={<ReturnParcels />} />
      </Route>
      <Route
        path={BASE_ROUTES.OAUTH}
        element={
          <RequireAuth isOauth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route path={generatePathWithModal(BASE_ROUTES.OAUTH_SELECT_SHIPPER)} element={<ShipperSelector isOauth />} />
      </Route>
      <Route
        path={BASE_ROUTES.INTERNATIONAL}
        element={
          <RequireAuth>
            <AccountEntry
              {...getAccountProps({
                hasInternationalSider: false,
                isInternationalHeader: false,
                noHeader: true,
                noSider: true
              })}
            />
          </RequireAuth>
        }
      >
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_OC)} element={<FPLOrderCreate />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_SERVICE_REGISTRATION)} element={<FPLRegistration />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_MMCC_ORDER_CREATION)} element={<FPLMMCCOrderCreation />} />
      </Route>
      <Route
        path={BASE_ROUTES.INTERNATIONAL}
        element={
          <RequireAuth>
            <AccountEntry {...getAccountProps({ hasInternationalSider: true, isInternationalHeader: true })} />
          </RequireAuth>
        }
      >
        <Route index path='*' element={<Navigate replace to={BASE_ROUTES.FPL_HOME} />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_OC_REQUEST_BY_BATCH)} element={<FPLOrderRequestList />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_OC_HISTORY)} element={<FPLBatchHistory />} />
        <Route path={BASE_ROUTES.FPL_PARCEL_DETAIL} element={<FPLParcelDetail />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_ORDER_BATCH)} element={<FPLOrderBatch />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_HOME)} element={<FPLHome />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_TRACKING_SEARCH)} element={<FPLSearchResult />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_ORDERS)} element={<FPLOrders showSearch={false} />} />
        <Route path={generatePathWithModal(BASE_ROUTES.FPL_MMCC_ORDER_BATCH)} element={<FPLMMCCOrderBatch />} />
      </Route>
      <Route path={BASE_ROUTES.PUBLIC} element={<ShipperSupportPublicForm />}>
        <Route
          path={generatePathWithModal(BASE_ROUTES.SHIPPER_SUPPORT)}
          element={<ShipperSupport showPublicFields />}
        />
        <Route
          path={generatePathWithModal(`${BASE_ROUTES.SHIPPER_SUPPORT}/${BASE_ROUTES.SHIPPER_SUPPORT_SUCCESS}`)}
          element={<ShipperSupportSuccess allowCreateAnotherTicket />}
        />
      </Route>
      <Route
        path={generatePathWithModal(`${BASE_ROUTES.INTERNATIONAL}/${BASE_ROUTES.FPL_TRACKING}`)}
        element={
          <RequireAuth>
            <AccountEntry {...getAccountProps({ hasInternationalSider: true, isInternationalHeader: false })} />
          </RequireAuth>
        }
      >
        <Route index path='*' element={<FPLTracking showSearch={false} />} />
      </Route>
    </Routes>
  )
}

export { NavigationRoutes }
