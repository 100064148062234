import { RouteModal } from 'components/RouteModal/RouteModal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getModalPath } from 'utils/getModalPath'
import { T, Tooltip } from '@nv/react-commons/src/Components'

import { faArrowSquareLeft } from '@fa-pro-light/faArrowSquareLeft'
import { faBox } from '@fa-pro-light/faBox'
import { faFileAlt } from '@fa-pro-light/faFileAlt'
import { faEnvelopeOpen } from '@fa-pro-light/faEnvelopeOpen'
import { OptionCard } from 'components/OptionCard'
import { SalesChannelsLink } from 'components/SalesChannelsLink'
import { Link } from 'react-router-dom'
import { ROUTES } from '../Base/constants'
import { mixpanelTrackStartOrderCreation, mixpanelTrackNinjaPackOrderCreationEvents } from 'components/Mixpanel/helpers'
import { NinjaPackLink } from 'components/NinjaPackLink'
import { getNinjaPacksLink } from 'utils/getNinjaPackLink'
import { trackEvent } from 'utils/GoogleAnalytics/GAEventTracker'
import { CREATE_NINJA_PACK_ORDER, CREATE_REGULAR_ORDER, CREATE_RETURN_ORDER } from 'utils/GoogleAnalytics/events'
import { Wrapper } from 'containers/OrderTypeModal'
import { ORDER_CREATE_TYPES, ORDER_TYPE_MODAL_PATH, RecipientType } from 'containers/OrderTypeModal/constants'
import Colors from 'themes/Colors'
import { Text } from 'components/Text'
import { CreateInternationalOrderButton } from 'containers/FPLOrderCreate/CreateInternationalOrderButton'
import {
  selectIsInternationalOCSupported,
  selectIsNinjaPackOCSupported,
  selectIsCorporateAWBOCSupported,
  selectIsParcelOCSupported,
  selectIsReturnOCSupported,
  selectIsDocumentAvailable,
  selectIsCorporateDocumentAvailable,
  selectIsB2BBundleSupported
} from 'containers/OrderCreate/selectors'
import { selectCountry, selectShipper } from 'containers/Base/selectors'
import { createStructuredSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { orderCreateCreators } from 'containers/Base/redux/order-create'

const StyledModal = styled(RouteModal)`
  .ant-modal-content {
    border-radius: 10px;
    > div {
      background-color: unset;
    }
  }

  .ant-modal-title {
    border-radius: 10px;
  }
`
const StyledLinkWrapper = styled.div`
  width: 100%;
`

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledText = styled(Text)`
  color: ${Colors.pinkishGrey};
`

const MenuWrapper: React.FC<{ className?: string }> = ({ className, children }) => (
  <Menu
    mode="vertical"
    className={className}
  >
    {children}
  </Menu>
);


const StyledMenu = styled(MenuWrapper)`
  &&& {
    border-right: 0;

    .ant-menu-submenu-title {
      padding: 0;
      height: 100%;
    }
    .ant-menu-submenu-arrow {
      top: 45%;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background: ${Colors.balanceRedLight};
    padding-top: 12px;
  }
`

function OrderTypeModalV2() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    isParcelOCSupported,
    isInternationalOCSupported,
    isNinjaPackOCSupported,
    isCorporateAWBOCSupported,
    isReturnOCSupported,
    isB2BBundleSupported,
    isDocumentTypeAvailable,
    isCorporateDocumentTypeAvailable,
    country,
    shipper
  } = useSelector(
    createStructuredSelector({
      isParcelOCSupported: selectIsParcelOCSupported(),
      isInternationalOCSupported: selectIsInternationalOCSupported(),
      isNinjaPackOCSupported: selectIsNinjaPackOCSupported(),
      isCorporateAWBOCSupported: selectIsCorporateAWBOCSupported(),
      isReturnOCSupported: selectIsReturnOCSupported(),
      isB2BBundleSupported: selectIsB2BBundleSupported(),
      isDocumentTypeAvailable: selectIsDocumentAvailable(),
      isCorporateDocumentTypeAvailable: selectIsCorporateDocumentAvailable(),
      country: selectCountry(),
      shipper: selectShipper()
    })
  )

  const isB2CSupported = isParcelOCSupported || isDocumentTypeAvailable
  const setRecipientType = type => dispatch(orderCreateCreators.set('recipientType', type))

  const onCancel = useCallback(() => {
    const pathSegments = location.pathname.split('/')
    if (pathSegments.length > 1) {
      const parentPath = pathSegments.slice(0, -1).join('/') || '/'
      navigate(parentPath)
    } else {
      navigate(-1)
    }
  }, [location, navigate])

  const isRegularOCSupported = useMemo(() => {
    return isParcelOCSupported || isDocumentTypeAvailable || isB2BBundleSupported
  }, [isParcelOCSupported, isDocumentTypeAvailable, isB2BBundleSupported])

  const renderRegularOrderDescription = useCallback(() => {
    if (isDocumentTypeAvailable || isCorporateDocumentTypeAvailable) {
      return <T id='create_regular_order_with_document_description' />
    }

    if (isRegularOCSupported) {
      return <T id='create_regular_order_description' />
    }

    return (
      <>
        <T id='create_regular_order_description' />
        &nbsp;
        <T id='contact_us_at' />
        &nbsp;
        <SalesChannelsLink country={country} />
        &nbsp;
        <T id='to_enable_this_option' />
      </>
    )
  }, [isDocumentTypeAvailable, isCorporateDocumentTypeAvailable, isRegularOCSupported, country])

  const renderRegularOCType = useCallback((isLink: Boolean = true) => {
    if (!country) return null

    return (
      <Wrapper
        to={ROUTES.OC_1}
        isLink={isRegularOCSupported && isLink}
        onLinkClick={() => isLink ? mixpanelTrackStartOrderCreation(ORDER_CREATE_TYPES.REGULAR) : {}}
      >
        <OptionCard
          icon={faEnvelopeOpen}
          label='create_a_regular_order'
          description={<StyledText>{renderRegularOrderDescription()}</StyledText>}
          onClick={() => isLink ? trackEvent(CREATE_REGULAR_ORDER, country) : {}}
          disabled={!isRegularOCSupported}
        />
      </Wrapper>
    )
  }, [country, isRegularOCSupported, renderRegularOrderDescription])

  const renderRegularOCWithOptions = () => {
    return <StyledMenu>
      <SubMenu
        key='regular'
        title={<StyledLinkWrapper>{renderRegularOCType(false)}</StyledLinkWrapper>}
      >
        <StyledMenuItem key='create_b2b_order' onClick={() => routeToOrderCreate(RecipientType.B2B)}>
          <Text id='create_b2b_order' />
        </StyledMenuItem>
        <StyledMenuItem key='create_b2c_order' onClick={() => routeToOrderCreate(RecipientType.B2C)}>
          <Text id='create_b2c_order' />
        </StyledMenuItem>
      </SubMenu>
    </StyledMenu>
  }

  const routeToOrderCreate = (recipientType) => {
    setRecipientType(recipientType)
    navigate(`${ROUTES.OC_1}`)
  }

  const renderNinjaPacksOCType = useCallback(() => {
    const { trackNPCreationStarted } = mixpanelTrackNinjaPackOrderCreationEvents()
    const ninjaPackLink = getNinjaPacksLink(country, shipper?.id)

    return (
      <Link to={`${ROUTES.OC_PACKS}/1`} onClick={trackNPCreationStarted}>
        <OptionCard
          icon={faBox}
          label='create_a_ninja_pack_order'
          description={
            <>
              <StyledText>
                <T id='create_ninja_pack_order_description' />
              </StyledText>
              {ninjaPackLink && <NinjaPackLink packLink={ninjaPackLink} />}
            </>
          }
          onClick={() => trackEvent(CREATE_NINJA_PACK_ORDER, country)}
        />
      </Link>
    )
  }, [country, shipper?.id])

  const renderCorporateManualAWBType = () => (
    <Link to={`${ROUTES.ORDER_CORPORATE_MANUAL_AWB}/1`}>
      <OptionCard
        icon={faFileAlt}
        label='create_a_corporate_manual_awb_order'
        description='create_a_corporate_manual_awb_order_description'
      />
    </Link>
  )

  const renderReturnOCType = () => (
    <Link
      to={`${ROUTES.ORDER_RETURN}/1`}
      onClick={() => mixpanelTrackStartOrderCreation(ORDER_CREATE_TYPES.RETURN)}
    >
      <OptionCard
        icon={faArrowSquareLeft}
        label='create_a_return_order'
        description='create_return_order_description'
        onClick={() => trackEvent(CREATE_RETURN_ORDER, country)}
      />
    </Link>
  )

  return (
    <StyledModal
      // path={getModalPath(location, ORDER_TYPE_MODAL_PATH)}  // change to this when we add the whole B2B and B2C
      path={getModalPath(location, 'new-select-order-type')}
      title={<T id={`choose_order_type`} />}
      onCancel={onCancel}
    >
      <StyledOptionsContainer>
        {isB2CSupported && isB2BBundleSupported && renderRegularOCWithOptions()}
        {(!isB2CSupported || !isB2BBundleSupported) && renderRegularOCType()}
        {isInternationalOCSupported && <CreateInternationalOrderButton shipperId={shipper?.id} />}
        {isNinjaPackOCSupported && renderNinjaPacksOCType()}
        {isCorporateAWBOCSupported && renderCorporateManualAWBType()}
        {isReturnOCSupported && renderReturnOCType()}
      </StyledOptionsContainer>
    </StyledModal>
  )
}

export default OrderTypeModalV2
