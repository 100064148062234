import React, { useContext } from 'react'
import { OCSubStep } from 'components/OCSubStep'
import { T } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { Hspace } from 'components/Hspace'
import { faEdit } from '@fa-pro-light/faEdit'

import { Description, FlexBlock, InlineBlock, SeperatedLine, StyledCard } from 'containers/FPLOrderCreate/styles'
import { INPUT_METHODS, SERVICE_TYPE } from 'containers/FPLOrderCreate/constants'
import { StyledIconButton } from './styles'
import { PREVIEW_SOURCE, TRANSLATION_KEY, OCMethod } from './constants'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'

interface PreviewStepInfoProps {
  quantity: number
  numberOfCartons?: number
  totalGoodsValue: number
  unit: string
  totalRecipient?: number
  titleIntlKey: string
  creationMethod?: string
  icon: JSX.Element
  source?: PREVIEW_SOURCE
  onEditStep: () => void
}

export const PreviewStepInfo = ({
  quantity,
  numberOfCartons,
  totalGoodsValue,
  unit,
  titleIntlKey,
  creationMethod,
  icon,
  totalRecipient,
  source,
  onEditStep
}: PreviewStepInfoProps) => {
  const intl = useIntl()

  const { ocState } = useContext(MMCCOrderCreationContext)
  const { selectedService } = ocState

  const renderPreviewDocument = () => {
    return (
      <>
        <InlineBlock>
          {quantity}{' '}
          {intl.formatMessage({ id: `${quantity > 1 ? TRANSLATION_KEY.FILES : TRANSLATION_KEY.FILE}` }).toLowerCase()}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id={TRANSLATION_KEY.FILE_SIZE} />
          </Description>
          <Hspace width={4} />
          <InlineBlock>
            {totalGoodsValue?.toFixed(2)}
            <Hspace width={3} />
            {unit?.toUpperCase()}
          </InlineBlock>
        </InlineBlock>
      </>
    )
  }

  const renderPreviewInfoByUploadMethod = () => {
    return (
      <>
        <InlineBlock>
          {quantity}{' '}
          {intl.formatMessage({ id: `${quantity > 1 ? TRANSLATION_KEY.BOXES : TRANSLATION_KEY.BOX}` }).toLowerCase()}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id={TRANSLATION_KEY.TOTAL_GOODS_VALUE} />
          </Description>
          <Hspace width={4} />
          <InlineBlock>
            {totalGoodsValue?.toFixed(2)}
            <Hspace width={3} />
            {unit?.toUpperCase()}
          </InlineBlock>
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id='international_upload_file_input_method' />
          </Description>
        </InlineBlock>
      </>
    )
  }

  const renderB2BBundleOrderPreview = () => {
    return (
      <>
        <InlineBlock>
          {intl.formatMessage({ id: TRANSLATION_KEY.X_RECIPIENTS_CAPITALIZED }, { x: totalRecipient })}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>{intl.formatMessage({ id: TRANSLATION_KEY.X_BUNDLES_CAPITALIZED }, { x: quantity })}</InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          {intl.formatMessage({ id: TRANSLATION_KEY.X_CARTONS_CAPITALIZED }, { x: numberOfCartons })}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id={TRANSLATION_KEY.TOTAL_GOODS_VALUE} />
          </Description>
          <Hspace width={4} />
          <InlineBlock>
            {totalGoodsValue?.toFixed(2)}
            <Hspace width={3} />
            {unit?.toUpperCase()}
          </InlineBlock>
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T
              id={
                creationMethod === INPUT_METHODS.FILE
                  ? 'international_upload_file_input_method'
                  : 'international_manual_keyboard_input_method'
              }
            />
          </Description>
        </InlineBlock>
      </>
    )
  }

  const renderPreviewInfoByInputMethod = () => {
    return (
      <>
        <InlineBlock>
          {totalRecipient}{' '}
          {intl
            .formatMessage({ id: `${totalRecipient > 1 ? TRANSLATION_KEY.RECIPIENTS : TRANSLATION_KEY.RECIPIENT}` })
            .toLowerCase()}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          {quantity}{' '}
          {intl.formatMessage({ id: `${quantity > 1 ? TRANSLATION_KEY.BOXES : TRANSLATION_KEY.BOX}` }).toLowerCase()}
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id={TRANSLATION_KEY.TOTAL_GOODS_VALUE} />
          </Description>
          <Hspace width={4} />
          <InlineBlock>
            {totalGoodsValue?.toFixed(2)}
            <Hspace width={3} />
            {unit?.toUpperCase()}
          </InlineBlock>
        </InlineBlock>
        <Hspace width={16} />
        <SeperatedLine />
        <Hspace width={16} />
        <InlineBlock>
          <Description>
            <T id='international_creation_method_by_keyboard' />
          </Description>
        </InlineBlock>
      </>
    )
  }

  const renderPreviewInfo = () => {
    if (source === PREVIEW_SOURCE.DOCUMENT) {
      return renderPreviewDocument()
    }
    if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
      return renderB2BBundleOrderPreview()
    }
    if (creationMethod === INPUT_METHODS.FILE) {
      return renderPreviewInfoByUploadMethod()
    }
    return renderPreviewInfoByInputMethod()
  }

  return (
    <>
      <StyledCard center='center'>
        <FlexBlock>
          <OCSubStep
            status={OCSubStep.DISABLE}
            title={titleIntlKey}
            icon={icon}
            renderPreview={() => {
              return <InlineBlock>{renderPreviewInfo()}</InlineBlock>
            }}
          />
          <StyledIconButton icon={faEdit} onClick={onEditStep} data-analyticsid='fpleditOCSummary' />
        </FlexBlock>
      </StyledCard>
    </>
  )
}
