import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { StyledCustomCard } from 'containers/FPLOrderCreate/styles'
import { StyleConfirmdButton } from './styles'
import { useIntl } from 'hooks/common'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import {
  buildB2BBoxRequests,
  buildB2BBundleRequest,
  buildB2CBoxRequests,
  buildBoxesForB2BOrders,
  isValidB2BForm,
  isValidBundle
} from './utils'
import { b2bBundleOC, batchB2COrders, batchOC } from 'services/api/fplApi'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { isEmpty } from 'lodash'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { OCMethod, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { message } from 'antd'
import { mpTrackConfirmTheOrder } from 'containers/FPLMixpanel/helpers'
import { PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { CREATION_METHODS, SERVICE_TYPE, SERVICE_TYPE_LABEL_IN_MIXPANEL } from 'containers/FPLOrderCreate/constants'
import { calculateResponseTime } from 'containers/FPLOrderCreate/utils'

const initialState = {
  batchError: false,
  batchMessage: '',
  isLoading: false
}

const ReviewStep = () => {
  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const { isLoading } = state
  const { ocState } = useContext(MMCCOrderCreationContext)

  const {
    boxOrders,
    senderAddress,
    pickupType,
    pickupAddress,
    selectedService,
    timeSlot,
    commercialFiles,
    additionalDocumentFiles,
    commercialStatus,
    additionalDocumentUploadStatus,
    allOrders,
    invalidOrders,
    ocMethod,
    mmccB2BFormGroup,
    customsCurrency,
    b2bBundleFormGroup,
    activeGroupId
  } = ocState

  const navigate = useNavigate()
  const { startCounting, getTime } = calculateResponseTime()

  const { destination_country: destinationCountry } = selectedService

  const allValidBundles = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
      const currentBundleEditing = b2bBundleFormGroup[activeGroupId]
      const inactiveBundles = []
      Object.values(b2bBundleFormGroup).forEach(bundle => {
        if (bundle.id !== activeGroupId) {
          inactiveBundles.push(bundle)
        }
      })
      return isValidBundle(currentBundleEditing, destinationCountry, inactiveBundles)
    }
    return true
  }, [activeGroupId, b2bBundleFormGroup, selectedService])

  const allValidB2BData = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.MMCCB2B) {
      const editingB2BForm = mmccB2BFormGroup[activeGroupId]
      return isValidB2BForm(editingB2BForm, destinationCountry)
    }
    return true
  }, [mmccB2BFormGroup, selectedService, activeGroupId])

  const isRequiredFieldsFilled = useMemo(() => {
    const isAddressFilled = !isEmpty(senderAddress)
    const isServiceFilled = !isEmpty(selectedService)

    let isBagsFilled = false
    switch (selectedService.type) {
      case SERVICE_TYPE.MMCCB2B:
        isBagsFilled = ocMethod === OCMethod.KEYBOARD ? allValidB2BData : boxOrders.length > 0
        break
      case SERVICE_TYPE.MMCCB2C:
        isBagsFilled = boxOrders.length > 0
        break
      default:
        // B2B Bundle
        isBagsFilled = allValidBundles
    }

    const isPickupInfoFilled = pickupType !== PICKUP_TYPES.SCHEDULED || (!isEmpty(timeSlot) && !isEmpty(pickupAddress))
    const isSupportingDocsFilled =
      commercialStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL &&
      additionalDocumentUploadStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
    return (
      isAddressFilled &&
      isServiceFilled &&
      isPickupInfoFilled &&
      isBagsFilled &&
      isSupportingDocsFilled
    )
  }, [
    senderAddress,
    selectedService,
    pickupType,
    boxOrders.length,
    timeSlot,
    pickupAddress,
    commercialStatus,
    additionalDocumentUploadStatus,
    commercialFiles,
    allValidBundles,
    allValidB2BData
  ])

  const showError = (error, validationErrors) => {
    let msg = error?.message
    if (validationErrors) {
      const errorMessages = Object.values(validationErrors).join(', ')
      msg = `${msg}: ${errorMessages}`
    }
    setState(prevState => ({ ...prevState, batchError: true, batchMessage: msg, isLoading: false }))
    message.error(msg, 10)
  }

  const navigateToBatchOrder = (batchId, responseTime, orderType) => {
    navigate(`${ROUTES.FPL_MMCC_ORDER_BATCH}?id=${batchId}`, {
      state: {
        responseTime,
        orderType
      }
    })
  }

  const trackConfirmOrder = (
    totalCreated: number,
    totalNeededActions: number,
    totalOrders: number,
    orderType: string
  ) => {
    const mpData = {
      totalCreated,
      totalNeededActions,
      totalOrders,
      inputMethod:
        ocMethod === OCMethod.UPLOAD ? CREATION_METHODS[OCMethod.UPLOAD] : CREATION_METHODS[OCMethod.KEYBOARD],
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      orderType: orderType
    }
    mpTrackConfirmTheOrder(mpData)
  }

  const submitB2BOrders = async () => {
    let boxSources = []
    if (ocMethod === OCMethod.KEYBOARD) {
      Object.values(mmccB2BFormGroup).forEach(group => {
        const boxesInRecipientGroup = buildBoxesForB2BOrders(Object.values(group.b2bBoxes), group.address, destinationCountry)
        boxSources = [...boxSources, ...boxesInRecipientGroup]
      })
    }
    if (ocMethod === OCMethod.UPLOAD) {
      boxSources = [...boxOrders]
    }
    const totalCreated = boxSources.length
    const totalNeededActions = Object.keys(allOrders).length - boxSources.length
    const totalOrders = Object.keys(allOrders).length
    trackConfirmOrder(
      totalCreated,
      totalNeededActions,
      totalOrders,
      SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    )
    const b2bPayload = buildB2BBoxRequests({
      boxOrders: boxSources,
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      ocMethod: ocMethod === OCMethod.UPLOAD ? OCMethod.UPLOAD : OCMethod.KEYBOARD,
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency,
      serviceType: selectedService.type
    })
    const res = await batchOC(b2bPayload)
    const responseTime = getTime()
    if (res.ok) {
      const batchId = res?.data?.data?.batch_id
      navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
    } else {
      showError(res.data?.error, res.data?.error?.validation_errors)
    }
  }

  const submitB2COrders = async () => {
    trackConfirmOrder(
      boxOrders.length,
      Object.keys(invalidOrders).length,
      Object.keys(invalidOrders).length + boxOrders.length,
      SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    )
    const b2cPayload = buildB2CBoxRequests({
      boxOrders,
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      ocMethod: OCMethod.UPLOAD, // Create B2C use Bulk Upload only
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency,
      serviceType: selectedService.type
    })
    const res = await batchB2COrders(b2cPayload)
    const responseTime = getTime()
    if (res.ok) {
      const batchId = res?.data?.data?.batch_id
      navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
    } else {
      showError(res.data?.error, res.data?.error?.validation_errors)
    }
  }

  const submitB2BBundleOrders = async () => {
    const b2bBundlePayload = buildB2BBundleRequest({
      bundles: Object.values(b2bBundleFormGroup),
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      ocMethod,
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency,
      serviceType: selectedService.type
    })
    const res = await b2bBundleOC(b2bBundlePayload)
    const responseTime = getTime()
    if (res.ok) {
      const batchId = res?.data?.data?.batch_id
      navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
    } else {
      showError(res.data?.error, res.data?.error?.validation_errors)
    }
  }

  const handleClickConfirm = () => {
    setState(prevState => ({ ...prevState, isLoading: true }))
    startCounting()
    switch (selectedService.type) {
      case SERVICE_TYPE.MMCCB2B:
        submitB2BOrders()
        break
      case SERVICE_TYPE.MMCCB2C:
        submitB2COrders()
        break
      default:
        // B2B Bundle
        submitB2BBundleOrders()
    }
  }

  const renderConfirmButton = useCallback(() => {
    if (!isRequiredFieldsFilled) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'international_check_and_fill_required_fields'
          })}
        >
          <StyleConfirmdButton
            disabled
            type='primary'
            onClick={handleClickConfirm}
            loading={isLoading}
            data-analyticsid='create4PLOrders'
          >
            <T id='confirm' />
          </StyleConfirmdButton>
        </Tooltip>
      )
    }

    return (
      <StyleConfirmdButton
        type='primary'
        onClick={handleClickConfirm}
        loading={isLoading}
        data-analyticsid='createMMCCOrders'
      >
        <T id='confirm' />
      </StyleConfirmdButton>
    )
  }, [handleClickConfirm, intl, isRequiredFieldsFilled, isLoading])

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <Row type='flex' justify='space-between' align='middle'>
          <T id='confirm_order' />
          {renderConfirmButton()}
        </Row>
      </StyledCustomCard>
    </>
  )
}

export { ReviewStep }
